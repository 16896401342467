import SurveyQuestions from "./SurveyQuestions";
import * as React from "react";
import { useState, useEffect } from "react";
import "./intro.scss";
import ReactSpeedometer from "react-d3-speedometer";
import { debounce } from "./utils";

const Intro = () => {
  const [isReady, setIsReady] = useState(false);
  const [movingValue, setMovingValue] = useState(75);
  const [forceRenderSetting, setForceRenderSetting] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (movingValue === 0) {
        clearTimeout(timer);
      } else if (movingValue === 75) {
        setMovingValue(25);
        setForceRenderSetting(false);
      } else {
        setMovingValue(75);
        setForceRenderSetting(false);
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [movingValue]);

  const debouncedHandleResize = debounce(function handleResize() {
    setForceRenderSetting(true);
    window.removeEventListener("resize", debouncedHandleResize);
  }, 1000);

  // Attach the event listener to the window object
  window.addEventListener("resize", debouncedHandleResize);

  return (
    <>
      {isReady === false && (
        <div className="introText">
          <h2>How do you measure up?</h2>
          <div className="speedometerDiv">
            <ReactSpeedometer
              forceRender={forceRenderSetting}
              maxValue={100}
              fluidWidth={true}
              needleHeightRatio={0.8}
              value={movingValue}
              customSegmentStops={[
                0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75,
                80, 85, 90, 95, 100,
              ]}
              segmentColors={[
                "red",
                "red",
                "red",
                "red",
                "red",
                "orange",
                "orange",
                "orange",
                "orange",
                "orange",
                "#c9b300",
                "#c9b300",
                "#c9b300",
                "#c9b300",
                "#c9b300",
                "#0a7c23",
                "#0a7c23",
                "#0a7c23",
                "#0a7c23",
                "#0a7c23",
              ]}
              needleTransitionDuration={3333}
              needleTransition="easeElastic"
              needleColor={"#FFFFFF"}
              textColor={"#000"}
              padding
              Vertical={15}
            />
          </div>
          <div className="legendDiv">
            <div className="legend">
              <div className="legend1"></div>
              <p>Beginner</p>
            </div>
            <div className="legend">
              <div className="legend2"></div>
              <p>Intermediate</p>
            </div>
            <div className="legend">
              <div className="legend3"></div>
              <p>Advanced</p>
            </div>
            <div className="legend">
              <div className="legend4"></div>
              <p>Expert</p>
            </div>
          </div>
          <p>
            Station10’s Data Capability Assessment will enable you to identify
            where you are on the Data Value Curve, and what your next steps and actions should be to unlock further value from your data investments.
          </p>
          <p>
            The Assessment has been specifically designed with Adobe in mind, to
            help ensure you can maximise the value that you are seeing from your
            Adobe investment.
          </p>

          <button
            onClick={() => {
              setIsReady(true);
              setMovingValue(0);
            }}
          >
            START
          </button>
        </div>
      )}
      {isReady === true && <SurveyQuestions />}
    </>
  );
};

export default Intro;
