import { useState } from "react";
import "./results.scss";
import ResultComponent from "./ResultComponent";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ResultsOverall from "./ResultsOverall";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import * as React from "react";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = mainColor => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
  palette: {
    primary: createColor("#FF5C00"),
    secondary: createColor("#052E5E"),
  },
  typography: {
    fontFamily: ["Regular", "sans-serif"].join(","),
  },
});

const Results = props => {
  const [section, setSection] = useState("overall6");

  const [value, setValue] = React.useState("overall6");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSection(newValue);
  };

  return (
    <>
      <section>
        <ThemeProvider theme={theme}>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              textColor="primary"
              aria-label="scrollable auto tabs example"
            >
              <Tab
                label="Overall"
                value="overall6"
                wrapped
                sx={{ color: "#052e5e" }}
              />
              <Tab
                value="category1"
                wrapped
                label="Data Collection and Integration"
                sx={{ color: "#052e5e" }}
              />
              <Tab
                label="Reporting and Analysis"
                value="category2"
                wrapped
                sx={{ color: "#052e5e" }}
              />
              <Tab
                label="Attribution and Marketing"
                value="category3"
                wrapped
                sx={{ color: "#052e5e" }}
              />
              <Tab
                label="Personalisation and Audience Segmentation"
                value="category4"
                wrapped
                sx={{ color: "#052e5e" }}
              />
              <Tab
                label="Data Governance and Privacy"
                value="category5"
                wrapped
                sx={{ color: "#052e5e" }}
              />
            </Tabs>
          </Box>
        </ThemeProvider>

        {section === "overall6" && (
          <ResultsOverall
            responses={props.responses}
            changeSection={handleChange}
          />
        )}
        {section !== "overall6" && (
          <ResultComponent section={section} responses={props.responses} />
        )}
      </section>
    </>
  );
};

export default Results;
