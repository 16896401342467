import { schemaBuilder } from "./utils";
import { AWSconfig } from "./config";

export const dynamoDBSendItem = async (
  assessmentID,
  totalScore,
  responses,
  personalDetails,
  categoryArray,
  categoryObject,
  categoryObjectUnWeighted
) => {
  // Load the AWS SDK for Node.js
  var AWS = require("aws-sdk");

  var creds = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: AWSconfig.identityPoolId,
  });
  AWS.config.credentials = creds;

  AWS.config.update({ region: AWSconfig.region });

  // Create DynamoDB service object
  var ddb = new AWS.DynamoDB({ apiVersion: AWSconfig.dynamoDBApiVersion });

  var myTable = "practitionerAssessmentResults";

  var params = {
    TableName: myTable,
    Item: schemaBuilder(
      assessmentID,
      personalDetails,
      totalScore,
      responses,
      categoryArray,
      categoryObject,
      categoryObjectUnWeighted
    ),
  };

  try {
    const data = await ddb.putItem(params).promise();
    if (data.$response.httpResponse.statusCode !== 200) {
      console.log("fail");
      //check if post request worked
      return false;
    } else {
      console.log("success");
      return true;
    }
  } catch (err) {
    console.log("fail2");
    return false;
  }
};
