export const email = (firstName, lastName, score, assessmentID, level) => {
  return `<body
  bgcolor="#EAF0F6"
  style="
    margin: 0 !important;
    padding: 0 !important;
    font-family: Arial, sans-serif;
    font-size: 15px;
    color: #23496d;
    word-break: break-word;
  "
  >
  <div
    id="preview_text"
    style="
      display: none;
      font-size: 1px;
      color: #eaf0f6;
      line-height: 1px;
      max-height: 0px;
      max-width: 0px;
      opacity: 0;
      overflow: hidden;
    "
  >
    Thank you for completing our survey | Station10&nbsp;
  </div>
  
  <!--[if gte mso 9]>
    <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
      <v:fill type="tile" size="100%,100%" color="#ffffff" />
    </v:background>
  <![endif]-->
  
  <div
    class="hse-body-background"
    style="background-color: #eaf0f6"
    bgcolor="#eaf0f6"
  >
    <table
      role="presentation"
      class="hse-body-wrapper-table"
      cellpadding="0"
      cellspacing="0"
      style="
        border-spacing: 0 !important;
        border-collapse: collapse;
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
        margin: 0;
        padding: 0;
        width: 100% !important;
        min-width: 320px !important;
        height: 100% !important;
      "
      width="100%"
      height="100%"
    >
      <tbody>
        <tr>
          <td
            class="hse-body-wrapper-td"
            valign="top"
            style="
              border-collapse: collapse;
              mso-line-height-rule: exactly;
              font-family: Arial, sans-serif;
              font-size: 15px;
              color: #23496d;
              word-break: break-word;
            "
          >
            <div
              id="hs_cos_wrapper_main"
              class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_dnd_area"
              style="color: inherit; font-size: inherit; line-height: inherit"
              data-hs-cos-general-type="widget"
              data-hs-cos-type="dnd_area"
            >
              <div
                id="section-0"
                class="hse-section hse-section-first"
                style="
                  padding-left: 10px;
                  padding-right: 10px;
                  padding-top: 20px;
                "
              >
                <!--[if !((mso)|(IE))]><!-- -->
                <div
                  class="hse-column-container"
                  style="
                    min-width: 280px;
                    max-width: 600px;
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    border-collapse: collapse;
                    border-spacing: 0;
                    background-color: #ffffff;
                    padding-bottom: 10px;
                    padding-top: 10px;
                  "
                  bgcolor="#FFFFFF"
                >
                  <!--<![endif]-->
  
                  <!--[if (mso)|(IE)]>
          <div class="hse-column-container" style="min-width:280px;max-width:600px;width:100%;Margin-left:auto;Margin-right:auto;border-collapse:collapse;border-spacing:0;">
          <table align="center" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;width:600px;" cellpadding="0" cellspacing="0" role="presentation" width="600" bgcolor="#FFFFFF">
          <tr style="background-color:#FFFFFF;">
        <![endif]-->
  
                  <!--[if (mso)|(IE)]>
      <td valign="top" style="width:600px;padding-bottom:10px; padding-top:10px;">
    <![endif]-->
                  <!--[if gte mso 9]>
      <table role="presentation" width="600" cellpadding="0" cellspacing="0" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;width:600px">
    <![endif]-->
                  <div id="column-0-0" class="hse-column hse-size-12">
                    <div
                      id="hs_cos_wrapper_module-0-0-0"
                      class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                      style="
                        color: inherit;
                        font-size: inherit;
                        line-height: inherit;
                      "
                      data-hs-cos-general-type="widget"
                      data-hs-cos-type="module"
                    >
                      <table
                        class="hse-image-wrapper"
                        role="presentation"
                        width="100%"
                        cellpadding="0"
                        cellspacing="0"
                        style="
                          border-spacing: 0 !important;
                          border-collapse: collapse;
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                        "
                      >
                        <tbody>
                          <tr>
                            <td
                              align="center"
                              valign="top"
                              style="
                                border-collapse: collapse;
                                mso-line-height-rule: exactly;
                                font-family: Arial, sans-serif;
                                color: #23496d;
                                word-break: break-word;
                                text-align: center;
                                padding: 10px 20px;
                                font-size: 0px;
                              "
                            >
                              <a
                                href="https://station10.co.uk/"
                                target="_blank"
                                style="
                                  color: #00a4bd;
                                  mso-line-height-rule: exactly;
                                "
                                data-hs-link-id="0"
                              >
                                <img
                                  alt="station10_logo_colour_orange_rgb-2"
                                  src="https://5322919.fs1.hubspotusercontent-na1.net/hub/5322919/hubfs/station10_logo_colour_orange_rgb-2.jpg?width=418&amp;upscale=true&amp;name=station10_logo_colour_orange_rgb-2.jpg"
                                  style="
                                    outline: none;
                                    text-decoration: none;
                                    -ms-interpolation-mode: bicubic;
                                    border: none;
                                    max-width: 100%;
                                    font-size: 16px;
                                  "
                                  width="209"
                                  align="middle"
                                  srcset="
                                    https://5322919.fs1.hubspotusercontent-na1.net/hub/5322919/hubfs/station10_logo_colour_orange_rgb-2.jpg?upscale=true&amp;width=418&amp;upscale=true&amp;name=station10_logo_colour_orange_rgb-2.jpg 209w,
                                    https://5322919.fs1.hubspotusercontent-na1.net/hub/5322919/hubfs/station10_logo_colour_orange_rgb-2.jpg?upscale=true&amp;width=836&amp;upscale=true&amp;name=station10_logo_colour_orange_rgb-2.jpg 418w
                                  "
                                  sizes="(max-width: 209px) 100vw, 209px"
                                />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!--[if gte mso 9]></table><![endif]-->
                  <!--[if (mso)|(IE)]></td><![endif]-->
  
                  <!--[if (mso)|(IE)]></tr></table><![endif]-->
                </div>
              </div>
  
              <div
                id="section-1"
                class="hse-section"
                style="padding-left: 10px; padding-right: 10px"
              >
                <!--[if !((mso)|(IE))]><!-- -->
                <div
                  class="hse-column-container"
                  style="
                    min-width: 280px;
                    max-width: 600px;
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    border-collapse: collapse;
                    border-spacing: 0;
                    background-color: #ffffff;
                    padding-top: 10px;
                  "
                  bgcolor="#ffffff"
                >
                  <!--<![endif]-->
  
                  <!--[if (mso)|(IE)]>
          <div class="hse-column-container" style="min-width:280px;max-width:600px;width:100%;Margin-left:auto;Margin-right:auto;border-collapse:collapse;border-spacing:0;">
          <table align="center" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;width:600px;" cellpadding="0" cellspacing="0" role="presentation" width="600" bgcolor="#ffffff">
          <tr style="background-color:#ffffff;">
        <![endif]-->
  
                  <!--[if (mso)|(IE)]>
      <td valign="top" style="width:600px;padding-top:10px;">
    <![endif]-->
                  <!--[if gte mso 9]>
      <table role="presentation" width="600" cellpadding="0" cellspacing="0" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;width:600px">
    <![endif]-->
                  <div
                    id="column_1627376080443_0"
                    class="hse-column hse-size-12"
                  >
                    <table
                      role="presentation"
                      cellpadding="0"
                      cellspacing="0"
                      width="100%"
                      style="
                        border-spacing: 0 !important;
                        border-collapse: collapse;
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                      "
                    >
                      <tbody>
                        <tr>
                          <td
                            class="hs_padded"
                            style="
                              border-collapse: collapse;
                              mso-line-height-rule: exactly;
                              font-family: Arial, sans-serif;
                              font-size: 15px;
                              color: #23496d;
                              word-break: break-word;
                              padding: 10px 20px;
                            "
                          >
                            <div
                              id="hs_cos_wrapper_module-2-0-0"
                              class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                              style="
                                color: inherit;
                                font-size: inherit;
                                line-height: inherit;
                              "
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="module"
                            >
                              <div
                                id="hs_cos_wrapper_module-2-0-0_"
                                class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"
                                style="
                                  color: inherit;
                                  font-size: inherit;
                                  line-height: inherit;
                                "
                                data-hs-cos-general-type="widget"
                                data-hs-cos-type="rich_text"
                              >
                                <p
                                  style="
                                    mso-line-height-rule: exactly;
                                    margin-top: 0cm;
                                    margin-right: 0cm;
                                    margin-bottom: 0cm;
                                    vertical-align: baseline;
                                    padding-left: 0cm;
                                    text-align: center;
                                    font-size: 20px;
                                    line-height: 146%;
                                  "
                                  align="center"
                                >
                                  <span style="color: #23496d"
                                    >  <strong
                                      >Thank you for completing our Adobe
                                      assessment</strong
                                    ></span
                                  >
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      id="hs_cos_wrapper_module-1-0-0"
                      class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                      style="
                        color: inherit;
                        font-size: inherit;
                        line-height: inherit;
                      "
                      data-hs-cos-general-type="widget"
                      data-hs-cos-type="module"
                    >
                      <table
                        class="hse-image-wrapper"
                        role="presentation"
                        width="100%"
                        cellpadding="0"
                        cellspacing="0"
                        style="
                          border-spacing: 0 !important;
                          border-collapse: collapse;
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                        "
                      >
                        <tbody>
                          <tr>
                            <td
                              align="center"
                              valign="top"
                              style="
                                border-collapse: collapse;
                                mso-line-height-rule: exactly;
                                font-family: Arial, sans-serif;
                                color: #23496d;
                                word-break: break-word;
                                text-align: center;
                                padding: 10px 20px;
                                font-size: 0px;
                              "
                            >
                              <a
                                href="https://station10.co.uk/"
                                target="_blank"
                                style="
                                  color: #00a4bd;
                                  mso-line-height-rule: exactly;
                                "
                                data-hs-link-id="1"
                              >
                                <img
                                  alt="Station10"
                                  src="https://5322919.fs1.hubspotusercontent-na1.net/hub/5322919/hubfs/3d-morse-code-orange%20ainmation%20.png?width=1120&amp;upscale=true&amp;name=3d-morse-code-orange%20ainmation%20.png"
                                  style="
                                    outline: none;
                                    text-decoration: none;
                                    -ms-interpolation-mode: bicubic;
                                    border: none;
                                    max-width: 100%;
                                    font-size: 16px;
                                  "
                                  width="560"
                                  align="middle"
                                  srcset="
                                    https://5322919.fs1.hubspotusercontent-na1.net/hub/5322919/hubfs/3d-morse-code-orange%20ainmation%20.png?upscale=true&amp;width=1120&amp;upscale=true&amp;name=3d-morse-code-orange%20ainmation%20.png  560w,
                                    https://5322919.fs1.hubspotusercontent-na1.net/hub/5322919/hubfs/3d-morse-code-orange%20ainmation%20.png?upscale=true&amp;width=2240&amp;upscale=true&amp;name=3d-morse-code-orange%20ainmation%20.png 1120w
                                  "
                                  sizes="(max-width: 560px) 100vw, 560px"
                                />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <table
                      role="presentation"
                      cellpadding="0"
                      cellspacing="0"
                      width="100%"
                      style="
                        border-spacing: 0 !important;
                        border-collapse: collapse;
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                      "
                    >
                      <tbody>
                        <tr>
                          <td
                            class="hs_padded"
                            style="
                              border-collapse: collapse;
                              mso-line-height-rule: exactly;
                              font-family: Arial, sans-serif;
                              font-size: 15px;
                              color: #23496d;
                              word-break: break-word;
                              padding: 10px 20px;
                            "
                          >
                            <div
                              id="hs_cos_wrapper_module_16273762547453"
                              class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                              style="
                                color: inherit;
                                font-size: inherit;
                                line-height: inherit;
                              "
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="module"
                            >
                              <div
                                id="hs_cos_wrapper_module_16273762547453_"
                                class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"
                                style="
                                  color: inherit;
                                  font-size: inherit;
                                  line-height: inherit;
                                "
                                data-hs-cos-general-type="widget"
                                data-hs-cos-type="rich_text"
                              >
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      role="presentation"
                      cellpadding="0"
                      cellspacing="0"
                      width="100%"
                      style="
                        border-spacing: 0 !important;
                        border-collapse: collapse;
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                      "
                    >
                      <tbody>
                        <tr>
                          <td
                            class="hs_padded"
                            style="
                              border-collapse: collapse;
                              mso-line-height-rule: exactly;
                              font-family: Arial, sans-serif;
                              font-size: 15px;
                              color: #23496d;
                              word-break: break-word;
                              padding: 10px 20px;
                            "
                          >
                            <div
                              id="hs_cos_wrapper_module_16273763024484"
                              class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                              style="
                                color: inherit;
                                font-size: inherit;
                                line-height: inherit;
                              "
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="module"
                            >
                              <div
                                id="hs_cos_wrapper_module_16273763024484_"
                                class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"
                                style="
                                  color: inherit;
                                  font-size: inherit;
                                  line-height: inherit;
                                "
                                data-hs-cos-general-type="widget"
                                data-hs-cos-type="rich_text"
                              >
                                <p
                                  style="
                                    mso-line-height-rule: exactly;
                                    text-align: center;
                                    font-size: 15px;
                                    line-height: 175%;
                                  "
                                  align="center"
                                >
                                  <p style="font-size: 15px"
                                    >Hi ${firstName},
                        </p>
                                  <p style="font-size: 15px"
                                  >Thanks for completing the Adobe Data Capability Assessment. You scored ${score} / 100 - your level is <strong>${level}</strong>. </p>
                                  
                               <p>Access your results, benchmarks, insights & recommendations here:</p>  
                    </p>
                    <div class="hse-column-container" style="min-width:280px; max-width:600px; width:100%; Margin-left:auto; Margin-right:auto; border-collapse:collapse; border-spacing:0; background-color:#ffffff" bgcolor="#ffffff">
                    <!--<![endif]-->
                    
                    <!--[if (mso)|(IE)]>
                      <div class="hse-column-container" style="min-width:280px;max-width:600px;width:100%;Margin-left:auto;Margin-right:auto;border-collapse:collapse;border-spacing:0;">
                      <table align="center" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;width:600px;" cellpadding="0" cellspacing="0" role="presentation" width="600" bgcolor="#ffffff">
                      <tr style="background-color:#ffffff;">
                    <![endif]-->
                
                    <!--[if (mso)|(IE)]>
                  <td valign="top" style="width:600px;">
                <![endif]-->
                <!--[if gte mso 9]>
                  <table role="presentation" width="600" cellpadding="0" cellspacing="0" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;width:600px">
                <![endif]-->
                <div id="column_1680004389537_0" class="hse-column hse-size-12">
                  <table role="presentation" cellpadding="0" cellspacing="0" width="100%" style="border-spacing:0 !important; border-collapse:collapse; mso-table-lspace:0pt; mso-table-rspace:0pt"><tbody><tr><td class="hs_padded" style="border-collapse:collapse; mso-line-height-rule:exactly; font-family:Arial, sans-serif; font-size:15px; color:#23496d; word-break:break-word; padding:10px 20px"><div id="hs_cos_wrapper_module_16644386641751" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style="color: inherit; font-size: inherit; line-height: inherit;" data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-spacing:0 !important; mso-table-lspace:0pt; mso-table-rspace:0pt; border-collapse:separate!important">
                    <tbody><tr>
                        <!--[if mso]>
                      <td align="center" valign="middle" bgcolor="#FF5C00" role="presentation"  valign="middle" style="border-radius:8px;cursor:auto;background-color:#FF5C00;padding:12px 18px;">
                    <![endif]-->
                    <!--[if !mso]><!-- -->
                      <td align="center" valign="middle" bgcolor="#FF5C00" role="presentation" style="border-collapse:collapse; mso-line-height-rule:exactly; font-family:Arial, sans-serif; font-size:15px; color:#23496d; word-break:break-word; border-radius:8px; cursor:auto; background-color:#ff5c00">
                   <!--<![endif]-->
                        <a href="${window.location.href}results/${assessmentID}" target="_blank" style="color:#00a4bd; mso-line-height-rule:exactly; font-size:16px; font-family:Arial, sans-serif; Margin:0; text-transform:none; text-decoration:none; padding:12px 18px; display:block" data-hs-link-id="1">
                          <strong style="color:#ffffff;font-weight:normal;text-decoration:none;font-style:normal;">View Your Results </strong>
                        </a>
                      </td>
                    </tr>
                  </tbody></table>
                </div></td></tr></tbody></table>
                </div>
                <!--[if gte mso 9]></table><![endif]-->
                <!--[if (mso)|(IE)]></td><![endif]-->
                
                
                    <!--[if (mso)|(IE)]></tr></table><![endif]-->
                
                    </div>
                                </p>
                              </div>
              
                              <!-- pasting here  -->
                              <div
                                id="hs_cos_wrapper_module_16273763024484_"
                                class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"
                                style="
                                  color: inherit;
                                  font-size: inherit;
                                  line-height: inherit;
                                "
                                data-hs-cos-general-type="widget"
                                data-hs-cos-type="rich_text"
                              >
                                <p
                                  style="
                                    mso-line-height-rule: exactly;
                                    text-align: center;
                                    font-size: 15px;
                                    line-height: 175%;
                                  "
                                  align="center"
                                >

                    <div class="hse-column-container" style="min-width:280px; max-width:600px; width:100%; Margin-left:auto; Margin-right:auto; border-collapse:collapse; border-spacing:0; background-color:#ffffff" bgcolor="#ffffff">
                    <!--<![endif]-->
                    
                    <!--[if (mso)|(IE)]>
                      <div class="hse-column-container" style="min-width:280px;max-width:600px;width:100%;Margin-left:auto;Margin-right:auto;border-collapse:collapse;border-spacing:0;">
                      <table align="center" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;width:600px;" cellpadding="0" cellspacing="0" role="presentation" width="600" bgcolor="#ffffff">
                      <tr style="background-color:#ffffff;">
                    <![endif]-->
                
                    <!--[if (mso)|(IE)]>
                  <td valign="top" style="width:600px;">
                <![endif]-->
                <!--[if gte mso 9]>
                  <table role="presentation" width="600" cellpadding="0" cellspacing="0" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;width:600px">
                <![endif]-->
                <div id="column_1680004389537_0" class="hse-column hse-size-12">
            
                </div>
                <!--[if gte mso 9]></table><![endif]-->
                <!--[if (mso)|(IE)]></td><![endif]-->
                
                
                    <!--[if (mso)|(IE)]></tr></table><![endif]-->
                
                    </div>
                                </p>
                              </div>
  
                              <!-- ending here -->
              
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!--[if gte mso 9]></table><![endif]-->
                  <!--[if (mso)|(IE)]></td><![endif]-->
  
                  <!--[if (mso)|(IE)]></tr></table><![endif]-->
                </div>
              </div>
  
              
  
              <!-- //INSERT HERE -->
  
              <div
                id="section_1664452311917"
                class="hse-section"
                style="padding-left: 10px; padding-right: 10px"
              >
                <!--[if !((mso)|(IE))]><!-- -->
                <div
                  class="hse-column-container"
                  style="
                    min-width: 280px;
                    max-width: 600px;
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    border-collapse: collapse;
                    border-spacing: 0;
                    background-color: #ffffff;
                  "
                  bgcolor="#ffffff"
                >
                  <!--<![endif]-->
  
                 
                    <div
                      id="hs_cos_wrapper_module-4-0-0"
                      class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                      style="
                        color: inherit;
                        font-size: inherit;
                        line-height: inherit;
                      "
                      data-hs-cos-general-type="widget"
                      data-hs-cos-type="module"
                    >
                      <!--[if gte mso 9]>
    <div style="line-height: 0; font-size: 0px">
      <v:rect xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style="width:600px; height:2pt;" fillcolor="none">
        <v:fill type="tile"/>
        <v:textbox inset="0,0,0,0">
    
          <div>
    
    <![endif]-->
                      <table
                        role="presentation"
                        width="100%"
                        align="center"
                        border="0"
                        style="
                          position: relative;
                          top: -1px;
                          min-width: 20px;
                          width: 100%;
                          max-width: 100%;
                          border-spacing: 0;
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-collapse: collapse;
                          font-size: 1px;
                        "
                      >
                        <tbody>
                          <tr>
                            <td
                              width="100%"
                              valign="middle"
                              style="
                                border-collapse: collapse;
                                mso-line-height-rule: exactly;
                                font-family: Arial, sans-serif;
                                font-size: 15px;
                                color: #23496d;
                                word-break: break-word;
                                line-height: 0;
                                border: transparent;
                                border-bottom: 1px solid #99acc2;
                                mso-border-bottom-alt: 2pt solid #99acc2;
                                border-bottom-width: 1px;
                              "
                            >
                              <!--[if mso 16]>&nbsp;<![endif]-->
                            </td>
                          </tr>
                        </tbody>
                      </table>
  
                      <!--[if gte mso 9]></div></v:textbox></v:rect></div><![endif]-->
                    </div>
                  </div>
                  <!--[if gte mso 9]></table><![endif]-->
                  <!--[if (mso)|(IE)]></td><![endif]-->
  
                  <!--[if (mso)|(IE)]></tr></table><![endif]-->
                </div>
              </div>
  
              <div
                id="section_1627377293857"
                class="hse-section"
                style="padding-left: 10px; padding-right: 10px"
              >
                <!--[if !((mso)|(IE))]><!-- -->
                <div
                  class="hse-column-container"
                  style="
                    min-width: 280px;
                    max-width: 600px;
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    border-collapse: collapse;
                    border-spacing: 0;
                    background-color: #ffffff;
                  "
                  bgcolor="#ffffff"
                >
                  <!--<![endif]-->
  
                  <!--[if (mso)|(IE)]>
          <div class="hse-column-container" style="min-width:280px;max-width:600px;width:100%;Margin-left:auto;Margin-right:auto;border-collapse:collapse;border-spacing:0;">
          <table align="center" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;width:600px;" cellpadding="0" cellspacing="0" role="presentation" width="600" bgcolor="#ffffff">
          <tr style="background-color:#ffffff;">
        <![endif]-->
  
                  <!--[if (mso)|(IE)]>
      <td valign="top" style="width:600px;">
    <![endif]-->
                  <!--[if gte mso 9]>
      <table role="presentation" width="600" cellpadding="0" cellspacing="0" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;width:600px">
    <![endif]-->
                  <div
                    id="column_1627377293857_0"
                    class="hse-column hse-size-12"
                  >
                    <table
                      role="presentation"
                      cellpadding="0"
                      cellspacing="0"
                      width="100%"
                      style="
                        border-spacing: 0 !important;
                        border-collapse: collapse;
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                      "
                    >
                      <tbody>
                        <tr>
                          <td
                            class="hs_padded"
                            style="
                              border-collapse: collapse;
                              mso-line-height-rule: exactly;
                              font-family: Arial, sans-serif;
                              font-size: 15px;
                              color: #23496d;
                              word-break: break-word;
                              padding: 10px 20px;
                            "
                          >
                            <div
                              id="hs_cos_wrapper_module_162737729381523"
                              class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                              style="
                                color: inherit;
                                font-size: inherit;
                                line-height: inherit;
                              "
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="module"
                            >
                              <table
                                role="presentation"
                                align="center"
                                style="
                                  border-spacing: 0 !important;
                                  border-collapse: collapse;
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  width: auto;
                                  text-align: center;
                                "
                                class="hs_cos_wrapper_type_social_module"
                                width="auto"
                              >
                                <tbody>
                                  <tr align="center">
                                    <td
                                      class="social-network-cell"
                                      style="
                                        border-collapse: collapse;
                                        mso-line-height-rule: exactly;
                                        font-family: Arial, sans-serif;
                                        font-size: 15px;
                                        color: #23496d;
                                        word-break: break-word;
                                      "
                                    >
                                      <table
                                        role="presentation"
                                        align="center"
                                        style="
                                          border-spacing: 0 !important;
                                          border-collapse: collapse;
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          width: auto;
                                          text-align: center;
                                        "
                                        class="hs_cos_wrapper_type_social_module_single"
                                        width="auto"
                                      >
                                        <tbody>
                                          <tr align="center">
                                            <td
                                              class="display_block_on_small_screens"
                                              style="
                                                border-collapse: collapse;
                                                mso-line-height-rule: exactly;
                                                font-family: Arial, sans-serif;
                                                font-size: 15px;
                                                color: #23496d;
                                                word-break: break-word;
                                                padding: 8px 5px;
                                                line-height: 1;
                                                vertical-align: middle;
                                              "
                                              valign="middle"
                                            >
                                              <a
                                                href="https://twitter.com/station10_uk"
                                                style="
                                                  color: #00a4bd;
                                                  mso-line-height-rule: exactly;
                                                  text-decoration: none !important;
                                                "
                                                data-hs-link-id="0"
                                                target="_blank"
                                              >
                                                <img
                                                  src="https://hs-5322919.f.hubspotemail.net/hs/hsstatic/TemplateAssets/static-1.114/img/hs_default_template_images/modules/Follow+Me+-+Email/twitter_circle_color.png"
                                                  alt="Twitter"
                                                  height="25"
                                                  style="
                                                    outline: none;
                                                    text-decoration: none;
                                                    -ms-interpolation-mode: bicubic;
                                                    border: none;
                                                    width: auto !important;
                                                    height: 25px !important;
                                                    vertical-align: middle;
                                                  "
                                                  valign="middle"
                                                  width="auto"
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
  
                                    <td
                                      class="social-network-cell"
                                      style="
                                        border-collapse: collapse;
                                        mso-line-height-rule: exactly;
                                        font-family: Arial, sans-serif;
                                        font-size: 15px;
                                        color: #23496d;
                                        word-break: break-word;
                                      "
                                    >
                                      <table
                                        role="presentation"
                                        align="center"
                                        style="
                                          border-spacing: 0 !important;
                                          border-collapse: collapse;
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          width: auto;
                                          text-align: center;
                                        "
                                        class="hs_cos_wrapper_type_social_module_single"
                                        width="auto"
                                      >
                                        <tbody>
                                          <tr align="center">
                                            <td
                                              class="display_block_on_small_screens"
                                              style="
                                                border-collapse: collapse;
                                                mso-line-height-rule: exactly;
                                                font-family: Arial, sans-serif;
                                                font-size: 15px;
                                                color: #23496d;
                                                word-break: break-word;
                                                padding: 8px 5px;
                                                line-height: 1;
                                                vertical-align: middle;
                                              "
                                              valign="middle"
                                            >
                                              <a
                                                href="https://www.linkedin.com/company/-station10-/"
                                                style="
                                                  color: #00a4bd;
                                                  mso-line-height-rule: exactly;
                                                  text-decoration: none !important;
                                                "
                                                data-hs-link-id="0"
                                                target="_blank"
                                              >
                                                <img
                                                  src="https://hs-5322919.f.hubspotemail.net/hs/hsstatic/TemplateAssets/static-1.114/img/hs_default_template_images/modules/Follow+Me+-+Email/linkedin_circle_color.png"
                                                  alt="LinkedIn"
                                                  height="25"
                                                  style="
                                                    outline: none;
                                                    text-decoration: none;
                                                    -ms-interpolation-mode: bicubic;
                                                    border: none;
                                                    width: auto !important;
                                                    height: 25px !important;
                                                    vertical-align: middle;
                                                  "
                                                  valign="middle"
                                                  width="auto"
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
  
                                    <td
                                      class="social-network-cell"
                                      style="
                                        border-collapse: collapse;
                                        mso-line-height-rule: exactly;
                                        font-family: Arial, sans-serif;
                                        font-size: 15px;
                                        color: #23496d;
                                        word-break: break-word;
                                      "
                                    >
                                      <table
                                        role="presentation"
                                        align="center"
                                        style="
                                          border-spacing: 0 !important;
                                          border-collapse: collapse;
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          width: auto;
                                          text-align: center;
                                        "
                                        class="hs_cos_wrapper_type_social_module_single"
                                        width="auto"
                                      >
                                        <tbody>
                                          <tr align="center">
                                            <td
                                              class="display_block_on_small_screens"
                                              style="
                                                border-collapse: collapse;
                                                mso-line-height-rule: exactly;
                                                font-family: Arial, sans-serif;
                                                font-size: 15px;
                                                color: #23496d;
                                                word-break: break-word;
                                                padding: 8px 5px;
                                                line-height: 1;
                                                vertical-align: middle;
                                              "
                                              valign="middle"
                                            >
                                              <a
                                                href="https://station10.co.uk/"
                                                style="
                                                  color: #00a4bd;
                                                  mso-line-height-rule: exactly;
                                                  text-decoration: none !important;
                                                "
                                                data-hs-link-id="2"
                                                target="_blank"
                                              >
                                                <img
                                                  src="https://hs-5322919.f.hubspotemail.net/hs/hsstatic/TemplateAssets/static-1.114/img/hs_default_template_images/modules/Follow+Me+-+Email/website_circle_color.png"
                                                  alt="Website"
                                                  height="25"
                                                  style="
                                                    outline: none;
                                                    text-decoration: none;
                                                    -ms-interpolation-mode: bicubic;
                                                    border: none;
                                                    width: auto !important;
                                                    height: 25px !important;
                                                    vertical-align: middle;
                                                  "
                                                  valign="middle"
                                                  width="auto"
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      role="presentation"
                      cellpadding="0"
                      cellspacing="0"
                      width="100%"
                      style="
                        border-spacing: 0 !important;
                        border-collapse: collapse;
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                      "
                    >
                      <tbody>
                        <tr>
                          <td
                            class="hs_padded"
                            style="
                              border-collapse: collapse;
                              mso-line-height-rule: exactly;
                              font-family: Arial, sans-serif;
                              font-size: 15px;
                              color: #23496d;
                              word-break: break-word;
                              padding: 10px 20px;
                            "
                          >
                            <div
                              id="hs_cos_wrapper_module_162737738729524"
                              class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                              style="
                                color: inherit;
                                font-size: inherit;
                                line-height: inherit;
                              "
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="module"
                            >
                              <div
                                id="hs_cos_wrapper_module_162737738729524_"
                                class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"
                                style="
                                  color: inherit;
                                  font-size: inherit;
                                  line-height: inherit;
                                "
                                data-hs-cos-general-type="widget"
                                data-hs-cos-type="rich_text"
                              >
                                <p
                                  style="
                                    mso-line-height-rule: exactly;
                                    line-height: 175%;
                                    text-align: center;
                                  "
                                  align="center"
                                >
                                  <em
                                    >Copyright © 2022 Station10, All rights
                                    reserved.</em
                                  >
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!--[if gte mso 9]></table><![endif]-->
                  <!--[if (mso)|(IE)]></td><![endif]-->
  
                  <!--[if (mso)|(IE)]></tr></table><![endif]-->
                </div>
              </div>
  
              <div
                id="section-6"
                class="hse-section hse-section-last"
                style="
                  padding-left: 10px;
                  padding-right: 10px;
                  padding-bottom: 20px;
                "
              >
                <!--[if !((mso)|(IE))]><!-- -->
                <div
                  class="hse-column-container"
                  style="
                    min-width: 280px;
                    max-width: 600px;
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    border-collapse: collapse;
                    border-spacing: 0;
                    padding-top: 30px;
                  "
                >
                  <!--<![endif]-->
  
                  <!--[if (mso)|(IE)]>
          <div class="hse-column-container" style="min-width:280px;max-width:600px;width:100%;Margin-left:auto;Margin-right:auto;border-collapse:collapse;border-spacing:0;">
          <table align="center" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;width:600px;" cellpadding="0" cellspacing="0" role="presentation">
          <tr>
        <![endif]-->
  
                  <!--[if (mso)|(IE)]>
      <td valign="top" style="width:600px;padding-top:30px;">
    <![endif]-->
                  <!--[if gte mso 9]>
      <table role="presentation" width="600" cellpadding="0" cellspacing="0" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;width:600px">
    <![endif]-->
                  <div
                    id="column_1632997019021_1"
                    class="hse-column hse-size-12"
                  >
                    <table
                      role="presentation"
                      cellpadding="0"
                      cellspacing="0"
                      width="100%"
                      style="
                        border-spacing: 0 !important;
                        border-collapse: collapse;
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                      "
                    >
                      <tbody>
                        <tr>
                          <td
                            class="hs_padded"
                            style="
                              border-collapse: collapse;
                              mso-line-height-rule: exactly;
                              font-family: Arial, sans-serif;
                              font-size: 15px;
                              color: #23496d;
                              word-break: break-word;
                              padding: 10px 20px;
                            "
                          >
                            <div
                              id="hs_cos_wrapper_module-6-0-0"
                              class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                              style="
                                color: inherit;
                                font-size: inherit;
                                line-height: inherit;
                              "
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="module"
                            >
                              <table
                                role="presentation"
                                class="hse-footer hse-secondary"
                                width="100%"
                                cellpadding="0"
                                cellspacing="0"
                                style="
                                  border-spacing: 0 !important;
                                  border-collapse: collapse;
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  font-family: Arial, sans-serif;
                                  font-size: 12px;
                                  line-height: 135%;
                                  color: #23496d;
                                  margin-bottom: 0;
                                  padding: 0;
                                "
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="
                                        border-collapse: collapse;
                                        mso-line-height-rule: exactly;
                                        font-family: Arial, sans-serif;
                                        font-size: 15px;
                                        color: #23496d;
                                        word-break: break-word;
                                        text-align: center;
                                        margin-bottom: 0;
                                        line-height: 135%;
                                        padding: 10px 20px;
                                      "
                                    >
                                      <p
                                        style="
                                          mso-line-height-rule: exactly;
                                          font-family: Arial, sans-serif;
                                          font-size: 12px;
                                          font-weight: normal;
                                          text-decoration: none;
                                          font-style: normal;
                                          color: #23496d;
                                          direction: lrt;
                                        "
                                        dir="lrt"
                                      >
                                        Station10, 1st Floor, Baird House, 15-17
                                        St Cross Street, Farringdon, London EC1N
                                        8UW, UK
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!--[if gte mso 9]></table><![endif]-->
                  <!--[if (mso)|(IE)]></td><![endif]-->
  
                  <!--[if (mso)|(IE)]></tr></table><![endif]-->
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  </body>
`;
};
