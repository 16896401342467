import { AWSconfig } from "./config";

export const dynamoDBGetItem = async (id) => {
  // Load the AWS SDK for Node.js
  var AWS = require("aws-sdk");

  var creds = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: AWSconfig.identityPoolId,
  });
  AWS.config.credentials = creds;

  AWS.config.update({ region: AWSconfig.region });

  // Create DynamoDB service object
  var ddb = new AWS.DynamoDB({ apiVersion: AWSconfig.dynamoDBApiVersion });

  var myTable = "practitionerAssessmentResults";

  var params = {
    TableName: myTable,
    Key: {
      assessmentID: { S: id },
    },
  };

  // Call DynamoDB to read the item from the table
  ddb.getItem(params, function (err, data) {
    if (err) {
      console.log("Error", err);
    } else {
      console.log("Success", data);
    }
  });

  try {
    const data = await ddb.getItem(params).promise();
    if (data.Item.length === 0) {
      return false;
    } else {
      return data;
    }
  } catch (err) {
    return false;
  }
};
