import { email } from "./emailTemplate";
import { numberRounder } from "./utils";
import { overallScoreInsights } from "./configResults";
import { AWSconfig } from "./config";
export const sendEmail = (assessmentID, personalDetails, totalScore) => {
  // Load the AWS SDK for Node.js
  var AWS = require("aws-sdk");

  const { Email, FirstName, LastName } = personalDetails;

  const getLevelRanking = (score) => {
    const ranking = {
      level1: { low: 0, high: 19 },
      level2: { low: 20, high: 39 },
      level3: { low: 40, high: 59 },
      level4: { low: 60, high: 79 },
      level5: { low: 80, high: 100 },
    };

    for (const [key, value] of Object.entries(ranking)) {
      if (score >= value.low && score <= value.high) {
        return key;
        //set state value for this level
      }
    }
  };

  const score = numberRounder(totalScore, 0);
  const level = getLevelRanking(score);
  const overallLevel = overallScoreInsights[level].levelName;

  const adobeEmail = "adobecapability@station10.co.uk";

  var creds = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: AWSconfig.identityPoolId,
  });
  AWS.config.credentials = creds;
  // Set the region

  AWS.config.update({ region: "eu-west-1" });

  // Create sendEmail params
  var params = {
    Destination: {
      /* required */
      CcAddresses: [
        //   'EMAIL_ADDRESS',
        /* more items */
      ],
      BccAddresses: ["adobecapability@station10.co.uk"],
      ToAddresses: [
        Email,
        /* more items */
      ],
    },
    Message: {
      /* required */
      Body: {
        /* required */
        Html: {
          Charset: "UTF-8",
          Data: email(FirstName, LastName, score, assessmentID, overallLevel),
        },
        Text: {
          Charset: "UTF-8",
          Data: "",
        },
      },
      Subject: {
        Charset: "UTF-8",
        Data: "Your Adobe Assessment Results by Station10",
      },
    },
    Source: "Station10 <adobecapability@station10.co.uk>" /* required */,
    ReplyToAddresses: [
      "adobecapability@station10.co.uk",
      /* more items */
    ],
  };

  // Create the promise and SES service object
  var sendPromise = new AWS.SES({ apiVersion: "2010-12-01" })
    .sendEmail(params)
    .promise();

  // Handle promise's fulfilled/rejected states
  sendPromise
    .then(function (data) {
      //console.log(data.MessageId);
    })
    .catch(function (err) {
      console.error(err, err.stack);
    });
};
