import { useState, useEffect } from "react";
import validator from "validator";
import { useParams } from "react-router-dom";
import Results from "./Results";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { dynamoDBGetItem } from "./dynamoDB_GetItem";
import ErrorPage from "./ErrorPage";
import { throttle } from "lodash";
import Loader from "./Loader";

const LandingPage = () => {
  const [isReady, setIsReady] = useState(false);
  const [showError, setShowError] = useState(false);
  const [endResults, setEndResults] = useState();
  const THROTTLE_INTERVAL = 10000; // Throttle interval in milliseconds
  const { assessmentID } = useParams();
  let data;

  const getItem = async () => {
     data = await dynamoDBGetItem(assessmentID);
  
    if (data === false) {
      //set state to error,
      setShowError(true);
    } else {
      setEndResults({
          totalScore: data.Item.overallScore.N,
          responses: {
            dataCollectionAndIntegrationQ1:
              data.Item.dataCollectionAndIntegrationQ1.S,
            dataCollectionAndIntegrationQ2:
              data.Item.dataCollectionAndIntegrationQ2.S,
            dataCollectionAndIntegrationQ3:
              data.Item.dataCollectionAndIntegrationQ3.S,
            dataCollectionAndIntegrationQ4:
              data.Item.dataCollectionAndIntegrationQ4.S,
            dataCollectionAndIntegrationQ5:
              data.Item.dataCollectionAndIntegrationQ5.S,
            reportingAndAnalysisQ1: data.Item.reportingAndAnalysisQ1.S,
            reportingAndAnalysisQ2: data.Item.reportingAndAnalysisQ2.S,
            reportingAndAnalysisQ3: data.Item.reportingAndAnalysisQ3.S,
            reportingAndAnalysisQ4: data.Item.reportingAndAnalysisQ4.S,
            reportingAndAnalysisQ5: data.Item.reportingAndAnalysisQ5.S,
            attributionAndMarketingQ1: data.Item.attributionAndMarketingQ1.S,
            attributionAndMarketingQ2: data.Item.attributionAndMarketingQ2.S,
            attributionAndMarketingQ3: data.Item.attributionAndMarketingQ3.S,
            attributionAndMarketingQ4: data.Item.attributionAndMarketingQ4.S,
            attributionAndMarketingQ5: data.Item.attributionAndMarketingQ5.S,
            personalisationAndAudienceSegmentationQ1:
              data.Item.personalisationAndAudienceSegmentationQ1.S,
            personalisationAndAudienceSegmentationQ2:
              data.Item.personalisationAndAudienceSegmentationQ2.S,
            personalisationAndAudienceSegmentationQ3:
              data.Item.personalisationAndAudienceSegmentationQ3.S,
            personalisationAndAudienceSegmentationQ4:
              data.Item.personalisationAndAudienceSegmentationQ4.S,
            personalisationAndAudienceSegmentationQ5:
              data.Item.personalisationAndAudienceSegmentationQ5.S,
            dataGovernanceAndPrivacyQ1: data.Item.dataGovernanceAndPrivacyQ1.S,
            dataGovernanceAndPrivacyQ2: data.Item.dataGovernanceAndPrivacyQ2.S,
            dataGovernanceAndPrivacyQ3: data.Item.dataGovernanceAndPrivacyQ3.S,
            dataGovernanceAndPrivacyQ4: data.Item.dataGovernanceAndPrivacyQ4.S,
            dataGovernanceAndPrivacyQ5: data.Item.dataGovernanceAndPrivacyQ5.S,
          },
          personalDetails: {
            LastName: data.Item.lastName,
            Email: data.Item.email,
            FirstName: data.Item.firstName,
          },
          categoryArray: [],
          categoryObject: JSON.parse(data.Item.categoryObject.S),
          categoryObjectUnWeighted: JSON.parse(
            data.Item.categoryObjectUnWeighted.S
          ),
        },
      );
      setIsReady(true);
    }
  };

  const throttledGetItem = throttle(async () => {
    setIsReady("loading");
    await getItem();
  }, THROTTLE_INTERVAL);

  const assessmentChecker = async () => {
    if (validator.isUUID(assessmentID)) {
      // Check if the getItem call was made within the last throttle interval
      const lastGetItemTime = window.localStorage.getItem("lastGetItemTime");
      if (
        !lastGetItemTime ||
        Date.now() - lastGetItemTime > THROTTLE_INTERVAL
      ) {
        await throttledGetItem();
        window.localStorage.setItem("lastGetItemTime", Date.now().toString());
        // Function hasnt run - Log it has run now;
      } else if (
        !lastGetItemTime ||
        Date.now() - lastGetItemTime < THROTTLE_INTERVAL
      ) {
        //failed against throttle, attempted too many times recently
        toast.warn("Results requested too many times - Please wait 5 seconds");
      }
    } else {
      //Assessment ID is not in correct format of uuid14
      setShowError(true);
    }
  };

  useEffect(() => {
    //getItem();
    assessmentChecker();
  }, [assessmentID]);


  return (
    <>
    <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {showError === true && <ErrorPage />}
      {isReady === "loading" && <Loader text={"Retrieving your results"} />}
      {showError === false && isReady === true && (
        <Results responses={endResults} />
      )}
    </>
  );
};

export default LandingPage;
