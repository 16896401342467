import "survey-core/defaultV2.min.css";
import { Model, Serializer } from "survey-core";
import { Survey } from "survey-react-ui";
import { useState } from "react";
import { surveyJson } from "./config";
import { sendEmail } from "./ses_sendemail";
import { dynamoDBSendItem } from "./dynamoDB_sendItem";
import { dynamoDBCheckUser } from "./dynamoDB_checkUser";
import { numberRounder, sumCategoryValues } from "./utils";
import "./survey.scss";
import { freeTextQuestion } from "./config";
import Results from "./Results";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Loader";


const storageItemKey = "my-survey";

function saveSurveyDataToLocalStorage (survey) {
  const data = survey.data;
  data.pageNo = survey.currentPageNo;
  window.localStorage.setItem(storageItemKey, JSON.stringify(data));
}

function removeSurveyDataFromLocalStorage() {
  
  window.localStorage.removeItem(storageItemKey);
}

// Add a custom `score` property to choice options
Serializer.addProperty("itemvalue", {
  name: "score:number",
});

Serializer.addProperty("itemvalue", {
  name: "weight:number",
});

Serializer.addProperty("question", {
  name: "category:text",
});

const SurveyQuestions = () => {
  const [complete, setComplete] = useState(false);
  const [endResults, setEndResults] = useState({});

  const survey = new Model(surveyJson);
  survey.showProgressBar = "bottom";
  survey.showQuestionNumbers = "on";
  // survey.sendResultOnPageNext = true;



  survey.onComplete.add(function (survey) {
    setComplete("loading");
    var totalScore = 0;
    let responses = {};
    let personalDetails = {};
    var categoryArray = []; //Maybe check if needed, currently only being used in dynamoDB function, could use categoryObject instead
    var categoryObject = {};
    var categoryObjectUnWeighted = {};

    var data = survey.data;
    //remove pageNo 

    if(data.pageNo){
      delete data.pageNo;
    }
    

  
    Object.keys(data).forEach(function (qName) {
      var question = survey.getQuestionByName(qName);
      var qValue = data[qName];

      if (question.choices && question.getType() === "radiogroup") {
        question.choices.forEach(function (choice) {
          if (choice.value === qValue) {
            var score = Number(choice.score * choice.weight);
            totalScore += +score;
            responses[question.name] = choice.value;
            categoryArray[question.category]
              ? categoryArray[question.category].push(score)
              : (categoryArray[question.category] = [score]);

            categoryObject[question.category]
              ? categoryObject[question.category].push(score)
              : (categoryObject[question.category] = [score]);

            categoryObjectUnWeighted[question.category]
              ? categoryObjectUnWeighted[question.category].push(choice.score)
              : (categoryObjectUnWeighted[question.category] = [choice.score]);
          }
        });
      } else if (question.name !== freeTextQuestion) {
        personalDetails[question.name] = question.value;
      } else {
        responses[question.name] = question.value;
      }
    });

     //define assessmentID
     const assessmentID = uuidv4();

    //Calculate score correctly here (send whole number rather than small weighted score)
    totalScore = numberRounder(sumCategoryValues(categoryObject));
    
    let postData;
    const postItem = async () => {
      postData = await dynamoDBSendItem(
        assessmentID,
        totalScore,
        responses,
        personalDetails,
        categoryArray,
        categoryObject,
        categoryObjectUnWeighted
      );
      /// set loading screen

      if (postData === true) {
        // if postRequest to Data is success, then
        setComplete(true);
        removeSurveyDataFromLocalStorage();

        //Send AWS SES email
        sendEmail(assessmentID, personalDetails, totalScore); //in future to make more robust can do a check on this request too
      } else if (postData === false) {
        saveSurveyDataToLocalStorage(survey);
        // postRequest was not successful, no internet connection
        toast.warn(
          "Sorry, something went wrong when submitting your results. Please check your internet connection and try again."
          , {
            toastId: "networkError"
          });
        setComplete(false);
      }
    };

    postItem();

    const endResults = {
      totalScore: totalScore,
      responses: responses,
      personalDetails: personalDetails,
      categoryArray: categoryArray,
      categoryObject: categoryObject,
      categoryObjectUnWeighted: categoryObjectUnWeighted,
    };
    setEndResults(endResults);

    
  });

    // Restore survey results
const prevData = window.localStorage.getItem(storageItemKey) || null;
if (prevData) {
  const data = JSON.parse(prevData);
  survey.data = data;
  if (data.pageNo) {
    survey.currentPageNo = data.pageNo;
  }
}

  return (
    <>
      <div className="surveyContainer"></div>
      {complete === false && <Survey id="surveyElement" model={survey} />}
      {complete === "loading" && <Loader />}
      {complete === true && <Results responses={endResults} />}
    </>
  );
};

export default SurveyQuestions;
