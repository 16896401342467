import {
  numberRounder,
  sumCategoryValues,
  sumSeparateCategories,
  debounce,
} from "./utils";
import { overallScoreInsights, sectionTitles } from "./configResults";
import ReactSpeedometer from "react-d3-speedometer";
import { useState, useEffect } from "react";
import "./results.scss";

const ResultsOverall = props => {
  const [overallScoreLevel, setoverallScoreLevel] = useState("");

  const [windowSize, setWindowSize] = useState("");

  const debouncedHandleResize = debounce(function handleResize() {
    setWindowSize(window.innerHeight + window.innerWidth);
    window.removeEventListener("resize", debouncedHandleResize);
  }, 1000);

  // Attach the event listener to the window object
  window.addEventListener("resize", debouncedHandleResize);

  const  categoryObject  = props.responses.categoryObject;
  //Array of each categories score
  const individualCategoriesScores = sumSeparateCategories(categoryObject);

  const findLevelRanking = score => {
    const ranking = {
      level1: { low: 0, high: 19 },
      level2: { low: 20, high: 39 },
      level3: { low: 40, high: 59 },
      level4: { low: 60, high: 79 },
      level5: { low: 80, high: 100 },
    };

    for (const [key, value] of Object.entries(ranking)) {
      if (score >= value.low && score <= value.high ) {
        setoverallScoreLevel(key);
        //set state value for this level
      }
    }
  };

  const overallScore = numberRounder(sumCategoryValues(categoryObject));

  useEffect(() => {
    findLevelRanking(overallScore);
  }, []);

  return (
    <>
      <div className="overallResults">
        <h2>Overall Results</h2>
        <div className="speedometerDiv">
          <ReactSpeedometer
            maxValue={100}
            fluidWidth={true}
            forceRender={true}
            test={windowSize}
            needleHeightRatio={0.8}
            value={overallScore}
            customSegmentStops={[
              0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80,
              85, 90, 95, 100,
            ]}
            segmentColors={[
              "red",
              "red",
              "red",
              "red",
              "orange",
              "orange",
              "orange",
              "orange",
              "#c9b300",
              "#c9b300",
              "#c9b300",
              "#c9b300",
              "#0a7c23",
              "#0a7c23",
              "#0a7c23",
              "#0a7c23",
              "#1bb504",
              "#1bb504",
              "#1bb504",
              "#1bb504",
            ]}
            needleTransitionDuration={3333}
            needleTransition="easeElastic"
            needleColor={"#000"}
            textColor={"#000"}
          />
        </div>
        <div className="legendDiv">
          <div className="legend">
            <div className="legend1"></div>
            <p>{overallScoreInsights.level1.levelName}</p>
          </div>
          <div className="legend">
            <div className="legend2"></div>
            <p>{overallScoreInsights.level2.levelName}</p>
          </div>
          <div className="legend">
            <div className="legend3"></div>
            <p>{overallScoreInsights.level3.levelName}</p>
          </div>
          <div className="legend">
            <div className="legend4"></div>
            <p>{overallScoreInsights.level4.levelName}</p>
          </div>
          <div className="legend">
            <div className="legend5"></div>
            <p>{overallScoreInsights.level5.levelName}</p>
          </div>
        </div>
        <div className="overallText">
          <p>
            You scored <strong>{overallScore}</strong> out of 100
          </p>
          <h3>
            {" "}
            {overallScoreLevel &&
              overallScoreInsights[overallScoreLevel].levelName}
          </h3>
          <div className="currentLevelText">
            {" "}
            {overallScoreLevel &&
              overallScoreInsights[overallScoreLevel].currentLevelText}
          </div>
          <div className="nextLevelText">
            {" "}
            {overallScoreLevel &&
              overallScoreInsights[overallScoreLevel].nextLevelText}
          </div>
        </div>
        <div className="overallIndividualCategories">
          {Object.keys(individualCategoriesScores).map((keyName, i) => (
            <div key={i}>
              <p className="overallIndividualCategoriesSectionTitle">
                {sectionTitles[keyName]}
              </p>
              <p>
                {" "}
                You scored: <b>{individualCategoriesScores[keyName]}</b> / 100
              </p>
              <button
                onClick={() => {
                  props.changeSection("", keyName);
                }}
              >
                View Section
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ResultsOverall;
