import ReactSpeedometer from "react-d3-speedometer";
import { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import "./results.scss";
import {
  numberRounder,
  sumCategoryScores,
  debounce,
  findOverallLevelRanking,
} from "./utils";
import {
  valueOfImprovement,
  exampleUseCases,
  exampleKPIsToFocus,
  subCategories,
  sectionTitles,
  sectionArray,
} from "./configResults";

const ResultComponent = props => {

  const [categoryScoreGroup, setCategoryScoreGroup] = useState("");
  const [windowSize, setWindowSize] = useState("");

  const debouncedHandleResize = debounce(function handleResize() {
    setWindowSize(window.innerHeight + window.innerWidth);
    window.removeEventListener("resize", debouncedHandleResize);
  }, 1000);

  // Attach the event listener to the window object
  window.addEventListener("resize", debouncedHandleResize);

  const {
    categoryArray,
    personalDetails,
    totalScore,
    responses,
    categoryObject,
    categoryObjectUnWeighted,
  } = props.responses;

  const categorySection = props.section;

  //first sums up the specific categorys scores array together, makes it a number, averages it and rounds it together
  const categoryScore = numberRounder(
    (Number(sumCategoryScores(categoryObject)[categorySection]) / 4) * 100,
    1
  );

  const categoryScoreLevel = Number(
    sumCategoryScores(categoryObject)[categorySection].toFixed(0)
  );

  const findLevelRanking = (score, type) => {
    const ranking = {
      level1: 0,
      level2: 1,
      level3: 2,
      level4: 3,
      level5: 4,
    };

    for (const [key, value] of Object.entries(ranking)) {
      if (score === value) {
        if (type === "category") {
          setCategoryScoreGroup(key);
        } else {
          return key;
        }
      }
    }
  };

  const sectionsScore = {
    section1: findLevelRanking(categoryObjectUnWeighted[categorySection][0]),
    section2: findLevelRanking(categoryObjectUnWeighted[categorySection][1]),
    section3: findLevelRanking(categoryObjectUnWeighted[categorySection][2]),
    section4: findLevelRanking(categoryObjectUnWeighted[categorySection][3]),
    section5: findLevelRanking(categoryObjectUnWeighted[categorySection][4]),
  };

  useEffect(() => {
    findLevelRanking(categoryScoreLevel, "category");
  }, [categoryScoreLevel]);

  const overallLevelRanking = findOverallLevelRanking(categoryScore);

  return (
    <>
      <div className="resultsContainer">
        <h2>{sectionTitles[categorySection]}</h2>
        <div className="speedometerDiv">
          <ReactSpeedometer
            maxValue={100}
            fluidWidth={true}
            forceRender={true}
            test={windowSize}
            needleHeightRatio={0.8}
            value={Number(categoryScore)}
            customSegmentStops={[
              0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80,
              85, 90, 95, 100,
            ]}
            segmentColors={[
              "red",
              "red",
              "red",
              "red",
              "red",
              "orange",
              "orange",
              "orange",
              "orange",
              "orange",
              "#c9b300",
              "#c9b300",
              "#c9b300",
              "#c9b300",
              "#c9b300",
              "#0a7c23",
              "#0a7c23",
              "#0a7c23",
              "#0a7c23",
              "#0a7c23",
            ]}
            needleTransitionDuration={3333}
            needleTransition="easeElastic"
            needleColor={"#000"}
            textColor={"#000"}
            paddingVertical={15}
          />
        </div>
        <div className="legendDiv">
          <div className="legend">
            <div className="legend1"></div>
            <p>Beginner</p>
          </div>
          <div className="legend">
            <div className="legend2"></div>
            <p>Intermediate</p>
          </div>
          <div className="legend">
            <div className="legend3"></div>
            <p>Advanced</p>
          </div>
          <div className="legend">
            <div className="legend4"></div>
            <p>Expert</p>
          </div>
        </div>

        <p className="textalignCenter">
          You scored{" "}
          <b className="categoryScore">{numberRounder(categoryScore, 0)}</b> out
          of 100 - {overallLevelRanking}
        </p>
        <div className="insightsContainer">
          <div className="insightsDiv">
            <h3>Value of Improvement</h3>
            {ReactHtmlParser(
              valueOfImprovement[categorySection][categoryScoreGroup]
            )}
          </div>
          <div className="insightsDiv">
            <h3>Example KPIs to focus:</h3>
            {ReactHtmlParser(
              exampleKPIsToFocus[categorySection][categoryScoreGroup]
            )}
          </div>
          <div className="insightsDiv">
            <h3>Example Use Cases:</h3>
            {ReactHtmlParser(
              exampleUseCases[categorySection][categoryScoreGroup]
            )}
          </div>
        </div>
        <p className="subCategoriesTitle textalignCenter">
          How can I improve my score?
        </p>
        <div className="subCategoriesContainer">
          {sectionArray.map( (section, key) => {
            return (
              <div key={key} className="subCategoryDiv">
                {" "}
                <h4>{subCategories[categorySection][section].title} </h4>
                <br></br>
                <p className="subCategorySubtitle">
                  {subCategories[categorySection][section][
                    sectionsScore[section]
                  ]
                    ? subCategories[categorySection][section][
                        sectionsScore[section]
                      ].subtitle
                    : "You are at the highest level, great job!"}
                </p>
                <br></br>
                <p>
                  {subCategories[categorySection][section][
                    sectionsScore[section]
                  ]
                    ? subCategories[categorySection][section][
                        sectionsScore[section]
                      ].text
                    : ""}
                </p>
              </div>
            );
          })}
        </div>
        <p>
          To discuss more what your score means and how you can improve it.
          Contact us{" "}
          <a href="mailto:adobecapability@station10.co.uk">adobecapability@station10.co.uk</a>
        </p>
      </div>
    </>
  );
};

export default ResultComponent;
