export const categoryWeights = {
  category1: 0.25,
  category2: 0.225,
  category3: 0.175,
  category4: 0.2,
  category5: 0.15,
};

export const valueOfImprovement = {
  category1: {
    level1: `<div>
                <p>
                1. Improved Data Accuracy and Quality: Improvement helps businesses establish a foundation for accurate and high-quality data collection. This lays the groundwork for better decision-making and performance measurement.
                </p>
                <p>
                2. Enhanced Customer Experience: Implementing basic Adobe Analytics tracking and integration allows businesses to gain insights into customer behaviour and preferences, ultimately leading to improved customer experiences.
                </p>
                <p>
                3. Streamlined Processes: Integrating Adobe Analytics with other Adobe and non-Adobe tools helps streamline processes, making it easier for teams to access and analyse data from multiple sources.
                </p>
            </div>`,
    level2: `<div>
                <p>
                1. Deeper Insights and Analysis: Improvement enables businesses to collect more detailed data, providing deeper insights into customer behaviour, preferences, and trends. This facilitates better-informed decision-making and strategy development.
                </p>
                <p>
                2. Increased Efficiency: Enhanced integration between Adobe Analytics and other tools helps streamline data analysis and reporting processes, improving efficiency and collaboration across teams.
                </p>
                <p>
                3. More Effective Marketing and Personalisation: By leveraging more comprehensive data, businesses can optimise marketing efforts and personalise experiences, resulting in better customer engagement and higher conversion rates.
                </p>
            </div>`,
    level3: `<div>
                <p> 1. Enhanced Data-Driven Decision Making: Improvement allows businesses to leverage more sophisticated data analysis capabilities, leading to better-informed decisions and more effective strategies. </p>
                <p> 2. Holistic Customer Understanding: Advanced data collection and integration enable businesses to develop a more comprehensive understanding of their customers, including cross-platform behaviour and preferences, which facilitates more targeted and personalised experiences.</p>
                <p> 3. Streamlined Data Management: By optimising data collection and integration processes, businesses can improve data management efficiency and reduce the time spent on manual data manipulation and aggregation. </p>
            </div>`,
    level4: `<div>
                <p> 1. Expert-Level Data-Driven Decision Making: Improvement enables businesses to leverage the full potential of Adobe Analytics, maximising the value of their data and empowering them to make more precise and informed decisions. </p>
                <p> 2. Highly Personalised Customer Experiences: Expert-level data collection and integration allow businesses to deliver highly personalised and relevant customer experiences across all touchpoints, leading to increased customer satisfaction and loyalty.</p>
                <p> 3. Competitive Advantage: By fully optimising Adobe Analytics capabilities, businesses can gain a significant competitive advantage, driving innovation and outperforming competitors in their respective markets.</p>
            </div>`,
    level5: `<div>
            <p>
            1. Market-Leading Data-Driven Decision Making: Improvement enables businesses to harness the full power of Adobe Analytics, maximising the value of their data and empowering them to make strategic and informed decisions that drive success. 
            </p>
            <p>
            2. Unparalleled Personalised Customer Experiences: Expert-level data collection and integration allow businesses to deliver highly personalised and relevant customer experiences across all channels, resulting in increased customer satisfaction and loyalty.
            </p>
            <p>
            3. Industry-Leading Competitive Advantage: By fully optimising Adobe Analytics capabilities, businesses can achieve an industry-leading competitive advantage, driving innovation and outpacing competitors in their respective markets. 
            </p>
        </div>`,
  },
  category2: {
    level1: `<div>
                <p>
                1. Data-Driven Insights: Improvement allows businesses to begin generating basic reports using Adobe Analytics, providing initial insights into user behavior and website performance.
                </p>
                <p>
                2. Informed Decision Making: By leveraging basic reporting capabilities, businesses can make more informed decisions, identify areas for improvement, and validate the effectiveness of their strategies.
                </p>
                <p>
                3. Early Detection of Issues: Regular reporting can help businesses identify issues or anomalies in website performance, enabling them to address problems before they escalate.
                </p>
            </div>`,
    level2: `<div>
                <p>
                1. Enhanced Data-Driven Decision Making: Improvement enables businesses to leverage more sophisticated reporting and analysis capabilities, leading to better-informed decisions and more effective strategies.
                </p>
                <p>
                2. Deeper Insights and Understanding: Intermediate reporting capabilities provide businesses with a deeper understanding of user behavior, website performance, and the effectiveness of their marketing efforts, enabling them to optimise their initiatives.
                </p>
                <p>
                3. Proactive Performance Monitoring: Utilising intermediate reporting and analysis tools allows businesses to monitor performance trends and detect anomalies more effectively, enabling them to address issues proactively.
                </p>
            </div>`,
    level3: `<div>
                <p>
                1. Superior Data-Driven Decision Making: Improvement enables businesses to leverage advanced reporting and analysis capabilities, empowering them to make highly informed decisions and optimise their strategies.
                </p>
                <p>
                2. Comprehensive Insights and Understanding: Advanced reporting capabilities provide businesses with a comprehensive understanding of user behavior, website performance, and marketing effectiveness, enabling them to identify opportunities for growth and optimisation.
                </p>
                <p>
                3. Efficient Performance Monitoring: Utilising advanced reporting and analysis tools allows businesses to efficiently monitor performance trends and detect anomalies, enabling them to address issues and optimise their efforts proactively.
                </p>
            </div>`,
    level4: `<div>
                <p>
                1. Exceptional Data-Driven Decision Making: Improvement enables businesses to leverage expert-level reporting and analysis capabilities, allowing them to make highly strategic and impactful decisions for maximum business growth.
                </p>
                <p>
                2. Holistic Insights and Understanding: Expert reporting capabilities provide businesses with a holistic understanding of user behavior, website performance, and marketing effectiveness, enabling them to identify opportunities for growth, optimisation, and innovation.
                </p>
                <p>
                3. Proactive and Agile Performance Monitoring: Utilising expert reporting and analysis tools allows businesses to proactively monitor performance trends, detect anomalies with precision, and rapidly adapt their strategies to address issues and seize opportunities.
                </p>
            </div>`,
    level5: `<div>
                <p>
                1. Unparalleled Data-Driven Decision Making: Improvement enables businesses to fully harness the power of Adobe Analytics, providing them with unparalleled data-driven insights to make strategic decisions that maximize business growth and success.
                </p>
                <p>
                2. Cutting-Edge Insights and Understanding: Expert reporting capabilities enable businesses to uncover granular insights into user behavior, website performance, and marketing effectiveness, empowering them to continuously refine and optimise their strategies.
                </p>
                <p>
                3. Proactive and Strategic Performance Monitoring: Utilising expert reporting and analysis tools allows businesses to stay ahead of the curve by proactively monitoring performance trends and rapidly adapting their strategies to address issues and capitalize on opportunities.
                </p>
        </div>`,
  },
  category3: {
    level1: `<div>
              <p>
              1. Improved Marketing Performance: Improvement helps businesses gain a basic understanding of marketing channels within Adobe Analytics, enabling them to better assess the performance of their marketing efforts and allocate resources effectively.
              </p>
              <p>
              2. Increased Return on Marketing Investment: By implementing basic attribution models, businesses can begin to measure the impact of their marketing activities, allowing them to optimise campaigns and achieve a higher return on investment.
              </p>
              <p>
              3. Streamlined Marketing Efforts: Basic integration of Adobe Analytics with marketing efforts enables businesses to track and analyse campaign performance more effectively, leading to streamlined marketing initiatives and better results.
              </p>
            </div>`,
    level2: `<div>
              <p>1. Enhanced Marketing Performance: Improvement enables businesses to leverage intermediate-level knowledge of marketing channels within Adobe Analytics, leading to more accurate assessments of marketing efforts and resource allocation.
              </p>
              <p>
              2. Greater Return on Marketing Investment: By utilising custom attribution models, businesses can measure the impact of their marketing activities more accurately, allowing for improved campaign optimisation and higher return on investment.
              </p>
              <p>
              3. Optimised Marketing Efforts: Intermediate integration of Adobe Analytics with marketing efforts allows businesses to track and analyse campaign performance more comprehensively, resulting in more targeted marketing initiatives and better results.
              </p>
            </div>`,
    level3: `<div>
              <p>
              1. Superior Marketing Performance: Improvement enables businesses to leverage advanced knowledge of marketing channels within Adobe Analytics, leading to a more precise assessment of marketing efforts and resource allocation.
              </p>
              <p>
              2. Optimised Return on Marketing Investment: By utilising multi-touch attribution models, businesses can measure the impact of their marketing activities with increased accuracy, allowing for better campaign optimisation and higher return on investment.
              </p>
              <p>
              3. Data-Driven Marketing Strategy: Advanced integration of Adobe Analytics with marketing efforts allows businesses to track and analyse campaign performance comprehensively, resulting in data-driven marketing strategies that yield better results.
              </p>
            </div>`,
    level4: `<div>
                <p>
                1. Unparalleled Marketing Performance: Businesses can benefit from expert knowledge of marketing channels within Adobe Analytics, resulting in a more accurate assessment of marketing efforts and resource distribution.
                </p>
                <p>
                2. Maximum Return on Marketing Investment: The utilisation of advanced multi-touch attribution models enables businesses to measure the impact of marketing activities with unrivaled precision, allowing for optimal campaign optimisation and elevated return on investment.
                </p>
                <p>
                3. Strategic Data-Driven Marketing: Expert integration of Adobe Analytics with marketing efforts empowers businesses to track and analyse campaign performance comprehensively, culminating in data-driven marketing strategies that yield optimal results.
                </p>
            </div>`,
    level5: `<div>
    <p>
    1. Industry-Leading Marketing Performance: Improvement enables businesses to fully harness the power of Adobe Analytics marketing capabilities, providing them with industry-leading marketing performance assessment and resource allocation.
    </p>
    <p>
    2. Optimised Marketing Investment: By expertly utilising advanced multi-touch attribution models, businesses can measure the impact of their marketing activities with exceptional accuracy, allowing for ultimate campaign optimisation and maximum return on investment.
    </p>
    <p>
    3. Data-Driven Marketing Mastery: Expert integration of Adobe Analytics with marketing efforts empowers businesses to track, analyse, and optimise campaign performance comprehensively, leading to the development and execution of data-driven marketing strategies that yield exceptional results.
    </p>
</div>`,
  },
  category4: {
    level1: `<div>
                  <p>
                  1. Improved Customer Experience: Improvement enables businesses to begin tracking customer interactions on their digital properties, leading to a better understanding of user behavior and opportunities for improving customer experience.
                  </p>
                  <p>
                  2. Informed Decision Making: By implementing basic tracking of website performance, businesses can make more informed decisions based on actual user behavior, resulting in more effective website improvements and increased user engagement.
                  </p>
                  <p>
                  3. Foundation for Future Growth: Establishing basic website performance tracking provides a foundation for future growth, as businesses can build on this initial step to further enhance their capabilities and gain deeper insights into customer behavior.
                  </p>
            </div>`,
    level2: `<div>
                <p>
                1. Enhanced Customer Experience: Improvement enables businesses to expand their tracking capabilities, providing deeper insights into user behavior and uncovering more opportunities to improve customer experience.
                </p>
                <p>
                2. Data-Driven Decision Making: By implementing intermediate tracking of website performance, businesses can make more data-driven decisions based on a comprehensive understanding of user behavior, leading to more effective website improvements and increased user engagement.
                </p>
                <p>
                3. Better Conversion Optimisation: Understanding how users interact with different elements on a website allows businesses to optimise conversion funnels, improving the overall conversion rate and generating more revenue.
                </p>
            </div>`,
    level3: `<div>
                <p>
                1. Superior Customer Experience: Improvement enables businesses to leverage advanced tracking capabilities, providing granular insights into user behavior and uncovering more opportunities to create a seamless customer experience.
                </p>
                <p>
                2. Advanced Data-Driven Decision Making: By implementing advanced tracking of website performance, businesses can make decisions based on detailed user behavior data, leading to more targeted and effective website improvements and increased user engagement.
                </p>
                <p>
                3. Efficient Conversion Optimisation: Gaining deep insights into user behavior and interactions with different elements on a website allows businesses to optimise conversion funnels with precision, leading to a higher overall conversion rate and generating more revenue.
                </p>
            </div>`,
    level4: `<div>
    <p>
    1. Personalised Customer Experience: Improvement enables businesses to utilise expert tracking capabilities, providing the deepest insights into user behavior and allowing for the creation of highly personalised customer experiences.
    </p>
    <p>
    2. Strategic Data-Driven Decision Making: By implementing expert tracking of website performance, businesses can make strategic decisions based on a comprehensive understanding of user behavior, leading to more impactful website improvements and increased user engagement.
    </p>
    <p>
    3. Maximum Conversion Optimisation: Leveraging granular insights into user behavior and interactions with different elements on a website allows businesses to optimise conversion funnels with the highest precision, leading to maximum overall conversion rates and generating more revenue.
    </p>
            </div>`,
    level5: `<div>
    <p>
    1. Industry-Leading Personalisation: Improvement enables businesses to fully harness the power of Adobe Analytics personalisation capabilities, providing them with industry-leading insights and tools to create highly tailored customer experiences that drive engagement and loyalty.
    </p>
    <p>
    2. Strategic Optimisation: By expertly utilising advanced tracking and optimisation features, businesses can make strategic decisions based on a comprehensive understanding of user behavior, leading to impactful website improvements, increased user engagement, and enhanced conversion rates.
    </p>
    <p>
    3. Data-Driven Marketing Mastery: Expert integration of Adobe Analytics with personalisation and optimisation efforts empowers businesses to develop and execute data-driven strategies that yield exceptional results and drive long-term business success.
    </p>
</div>`,
  },
  category5: {
    level1: `<div>
    <p>
    1. Streamlined Data Management: Improvement allows businesses to implement a basic data governance structure, making data management more efficient and reducing the potential for data-related errors.
    </p>
    <p>
    2. Improved Data Quality: Establishing a basic data governance framework ensures data consistency across different teams and tools, leading to improved data quality and more accurate insights.
    </p>
    <p>
    3. Enhanced Data-Driven Decision Making: With better data quality, businesses can make more informed decisions, leading to improved performance and a better return on investment for their analytics efforts.
    </p>
            </div>`,
    level2: `<div>
    <p>
    1. Enhanced Data-Driven Insights: Improvement allows businesses to capture more detailed data and insights, leading to better decision-making and an increased return on investment for their analytics efforts.
    </p>
    <p>
    2. Comprehensive Data Coverage: With an intermediate data layer, businesses can collect more data points and track a wider range of user interactions, resulting in a more complete understanding of user behavior.
    </p>
    <p>
    3. Improved Data Integration: Implementing an intermediate data layer allows for smoother integration of Adobe Analytics with other data sources, providing a unified view of data across different platforms and tools.
    </p>
            </div>`,
    level3: `<div>
    <p>
    1. Personalisation and Segmentation: Improvement allows businesses to leverage advanced data layer capabilities to personalise and segment user experiences, leading to increased user engagement, retention, and conversions.
    </p>
    <p>
    2. Advanced Analytics: With an advanced data layer, businesses can perform more sophisticated analyses, uncovering deeper insights and enabling better decision-making.
    </p>
    <p>
    3. Real-time Data and Insights: Implementing an advanced data layer enables real-time data collection and analysis, allowing businesses to react quickly to changing market conditions and customer needs.
    </p>
            </div>`,
    level4: `<div>
              <p>
              1. Hyper-Personalisation: Improvement enables businesses to leverage an Optimised data layer for hyper-personalisation, delivering highly relevant and targeted experiences to individual users, resulting in increased customer satisfaction and loyalty.
              </p>
              <p>
              2. Advanced Data-Driven Optimisation: With an Optimised data layer, businesses can identify and act on data-driven insights more effectively, leading to continuous optimisation of user experiences and improved business outcomes.
              </p>
              <p>
              3. Streamlined Data Management: Implementing an Optimised data layer simplifies data management processes and reduces the likelihood of data errors, ensuring higher quality data and more accurate insights.
              </p>
            </div>`,
    level5: `<div>
              <p>
              1. Best-in-Class Data Governance: Improvement enables businesses to establish and maintain industry-leading data governance practices, ensuring the highest level of data quality, accuracy, and consistency while fostering a culture of data-driven decision-making.
              </p>
              <p>
              2. Robust Privacy and Compliance Management: Expert use of Adobe Analytics data governance and privacy capabilities ensures full compliance with ever-evolving data protection regulations and helps maintain customer trust in the company's data handling practices.
              </p>
              <p>
              3. Strategic Data Management: Expert-level data governance and privacy practices empower businesses to harness the full potential of their data, integrating and managing it across multiple platforms, tools, and teams for a unified and actionable view of their data ecosystem.
              </p>
        </div>`,
  },
};

export const exampleKPIsToFocus = {
  category1: {
    level1: `<div>
    <p>
    1. Bounce Rate: With basic tracking in place, businesses can analyse bounce rates and identify areas where improvements can be made to retain visitors and encourage further engagement
    </p>
    <p>
    2. Page Views and Time on Site: Basic tracking allows businesses to monitor page views and time spent on the site, providing insights into which pages are most engaging and where improvements can be made.
    </p>
    <p>
    3. Conversion Rates: Implementing basic tracking helps businesses track conversion rates for specific goals, such as newsletter sign-ups, purchases, or form submissions.
    </p>
    <p>
    4. Cross-Platform Data Consistency: Basic integration with other Adobe and non-Adobe tools helps ensure consistent data collection and reporting across platforms, leading to more accurate performance measurement and analysis.
    </p>
              </div>`,
    level2: `<div>
                <p>
                1. User Engagement Metrics: Comprehensive tracking enables businesses to monitor a wider range of user engagement metrics, such as clicks, scrolls, and video plays, helping to identify opportunities for improvement and optimisation.
                </p>
                <p>
                2. E-Commerce Metrics: Collecting detailed e-commerce data, such as product views, add-to-cart actions, and revenue, helps businesses better understand customer purchase behaviour and optimise their online store.
                </p>
                <p>
                3. Cross-Channel Performance: Advanced integration with other Adobe and non-Adobe tools allows businesses to more effectively measure and optimise cross-channel performance, ensuring a consistent and cohesive customer experience.
                </p>
                <p>
                4. Return on Marketing Investment (ROMI): With a deeper understanding of customer behaviour and more effective marketing efforts, businesses can achieve a higher return on marketing investment, driving growth and profitability.
                </p>
              </div>`,
    level3: `<div> 
   <p> 1. Customer Lifetime Value (CLV): With a more comprehensive understanding of customer behaviour and preferences, businesses can optimise their marketing efforts and improve customer retention, increasing overall customer lifetime value. </p>
   <p> 2. Customer Acquisition Cost (CAC): Leveraging advanced data analysis capabilities and a fully integrated data ecosystem, businesses can optimise their marketing spend and reduce customer acquisition costs.</p>
   <p> 3. Customer Satisfaction and Net Promoter Score (NPS): By delivering more targeted and personalised experiences, businesses can improve customer satisfaction and loyalty, resulting in higher Net Promoter Scores.</p>
   <p> 4. Multi-Channel Attribution: With a fully integrated data ecosystem, businesses can more accurately measure and attribute performance across multiple channels and touchpoints, leading to better-informed marketing decisions and resource allocation.</p>
    </div>`,
    level4: `<div>
                  <p> 1. Return on Investment (ROI): Expert-level tracking and integration enable businesses to optimise their marketing spend and efforts, resulting in a higher return on investment across all channels. </p>
                  <p> 2. Conversion Rate Optimisation: With advanced data collection and analysis capabilities, businesses can identify areas for improvement in the conversion funnel, leading to higher conversion rates and increased revenue. </p>
                  <p> 3. Customer Retention and Churn Rate: Highly personalised customer experiences contribute to improved customer retention and reduced churn rates, directly impacting the bottom line. </p>
                  <p> 4. Market Share and Growth: Leveraging expert-level Adobe Analytics capabilities enables businesses to gain a competitive edge, driving market share growth and overall business success. </p>
              </div>`,
    level5: `<div>
              <p>
              1. Expert-level tracking and integration enable businesses to continuously improve their marketing efforts, resulting in optimised performance and higher returns on investment across all channels
              </p>
              <p>
              2. Advanced Conversion Funnel Optimisation: With granular data collection and analysis capabilities, businesses can identify opportunities for improvement throughout the conversion funnel, leading to higher conversion rates and increased revenue. 
              </p>
              <p>
              3. Enhanced Customer Retention and Reduced Churn: Highly personalised customer experiences contribute to improved customer retention and reduced churn rates, directly impacting the bottom line. 
              </p>
              <p>
              4. Dominant Market Share and Sustained Growth: Leveraging expert-level Adobe Analytics capabilities enables businesses to achieve a dominant market share and drive sustainable growth, ensuring long-term success.
              </p>
          </div>`,
  },
  category2: {
    level1: `<div>
                  <p>
                  1. Website Performance Metrics: Basic reporting allows businesses to monitor key website performance metrics, such as page load time, bounce rate, and session duration, helping to identify areas for improvement.
                  </p>
                  <p>
                  2. Traffic Sources: By understanding the sources of website traffic, businesses can optimise their marketing efforts and allocate resources more effectively.
                  </p>
                  <p>
                  3. User Engagement: Generating reports on user engagement metrics, such as pages per session and time on site, can help businesses identify opportunities to improve user experience and increase conversions.
                  </p>
              </div>`,
    level2: `<div>
    <p>
    1. Conversion Rates: Intermediate reporting and analysis capabilities enable businesses to identify areas for improvement in the conversion funnel, leading to higher conversion rates and increased revenue.
    </p>
    <p>
    2. Marketing Performance: By understanding the performance of various marketing channels and campaigns, businesses can optimise their marketing efforts and allocate resources more effectively.
    </p>
    <p>
    3. Customer Retention: Gaining deeper insights into user behavior and preferences allows businesses to improve user experience and engagement, resulting in increased customer retention.
    </p>
              </div>`,
    level3: `<div>
    <p>
    1. Revenue Growth: Advanced reporting and analysis capabilities enable businesses to optimise their strategies, leading to increased revenue growth and business success.
    </p>
    <p>
    2. Marketing ROI: By understanding the performance of various marketing channels and campaigns at an advanced level, businesses can optimise their marketing efforts and achieve a higher return on investment (ROI).
    </p>
    <p>
    3. User Experience: Gaining comprehensive insights into user behavior and preferences allows businesses to improve user experience and engagement, resulting in increased customer satisfaction and loyalty.
    </p>
    <p>
    4. Customer Lifetime Value (CLV): Leveraging advanced reporting and analysis capabilities can help businesses better understand customer behavior, enabling them to improve customer retention and increase customer lifetime value.
    </p>
              </div>`,
    level4: `<div>
    <p>
    1. Market Share: Expert reporting and analysis capabilities enable businesses to optimise their strategies and outperform competitors, leading to increased market share.
    </p>
    <p>
    2. Marketing Efficiency: By understanding the performance of various marketing channels and campaigns at an expert level, businesses can optimise their marketing efforts to achieve maximum efficiency and effectiveness.
    </p>
    <p>
    3. Customer Satisfaction: Gaining holistic insights into user behavior and preferences allows businesses to provide an exceptional user experience, resulting in increased customer satisfaction and advocacy.
    </p>
    <p>
    4. Innovation and Growth: Leveraging expert reporting and analysis capabilities can help businesses uncover new opportunities for growth and innovation, driving long-term business success.
    </p>
              </div>`,
    level5: `<div>
    <p>
    1. Competitive Advantage: Expert reporting and analysis capabilities enable businesses to gain a significant competitive edge, allowing them to outperform competitors and capture market share.
    </p>
    <p>
    2. Marketing Optimisation: By fully understanding the performance of various marketing channels and campaigns, businesses can optimise their marketing efforts to achieve maximum efficiency, effectiveness, and return on investment.
    </p>
    <p>
    3. Customer Loyalty: Gaining cutting-edge insights into user behavior and preferences allows businesses to create highly personalised experiences, resulting in increased customer loyalty and advocacy.
    </p>
    <p>
    4. Business Growth and Innovation: Leveraging expert reporting and analysis capabilities can help businesses uncover new opportunities for growth and innovation, driving long-term success and market leadership.
    </p>
          </div>`,
  },
  category3: {
    level1: `<div>
    <p>
    1. Website Traffic: Gaining a basic understanding of marketing channels and implementing basic campaign tracking can help businesses optimise their marketing efforts to drive more website traffic.
    </p>
    <p>
    2. Conversion Rates: Measuring marketing performance using basic attribution models enables businesses to identify and optimise high-performing campaigns, leading to increased conversion rates.
    </p>
    <p>
    3. Cost per Acquisition (CPA): By tracking and analysing marketing campaigns, businesses can identify the most cost-effective channels and campaigns, leading to a lower cost per acquisition.
    </p>
              </div>`,
    level2: `<div>
    <p>
    1. Website Traffic Growth: Leveraging an intermediate understanding of marketing channels and implementing moderate campaign tracking can help businesses optimise their marketing efforts to drive sustained website traffic growth.
    </p>
    <p>
    2. Conversion Rate Improvement: Measuring marketing performance using custom attribution models enables businesses to identify and optimise high-performing campaigns more effectively, leading to continuous improvement in conversion rates.
    </p>
    <p>
    3. Return on Ad Spend (ROAS): By tracking and analysing marketing campaigns more comprehensively, businesses can identify the most cost-effective channels and campaigns, leading to a higher return on ad spend.
    </p>
           
              </div>`,
    level3: `<div>
    <p>
    1. Sustained Website Traffic Growth: Leveraging advanced knowledge of marketing channels and implementing advanced campaign tracking can help businesses optimise their marketing efforts to drive sustained website traffic growth.
    </p>
    <p>
    2. Higher Conversion Rates: Measuring marketing performance using multi-touch attribution models enables businesses to identify and optimise high-performing campaigns more effectively, leading to continuous improvement in conversion rates and revenue generation.
    </p>
    <p>
    3. Increased Return on Ad Spend (ROAS): By tracking and analysing marketing campaigns comprehensively, businesses can identify the most cost-effective channels and campaigns, leading to a higher return on ad spend and improved marketing efficiency.
    </p>
           
              </div>`,
    level4: `<div>
    <p>
    1. Exponential Website Traffic Growth: Capitalizing on expert knowledge of marketing channels and incorporating advanced campaign tracking can empower businesses to optimise their marketing endeavors, resulting in exponential website traffic growth.
    </p>
    <p>
    2. Exceptional Conversion Rates: Employing advanced multi-touch attribution models facilitates the identification and optimisation of high-performing campaigns more effectively, leading to continuous improvement in conversion rates and revenue generation.
    </p>
    <p>
    3. Increased Return on Ad Spend (ROAS): Comprehensive tracking and analysis of marketing campaigns allow businesses to pinpoint the most cost-effective channels and campaigns, yielding a higher return on ad spend and enhanced marketing efficiency.
    </p>
           
              </div>`,
    level5: `<div>
    <p>
    1. Market Leadership: Expert knowledge of marketing channels and advanced campaign tracking can help businesses optimise their marketing efforts, driving exponential website traffic growth and solidifying their position as market leaders.
    </p>
    <p>
    2. Unparalleled Conversion Rates: Employing advanced multi-touch attribution models allows businesses to identify and optimise high-performing campaigns effectively, leading to continuous improvement in conversion rates and substantial revenue generation.
    </p>
    <p>
    3. Maximum Return on Ad Spend (ROAS): Comprehensive tracking and analysis of marketing campaigns enable businesses to pinpoint the most cost-effective channels and campaigns, yielding the highest return on ad spend and ensuring marketing efficiency.
    </p>
    <p>
    4. Customer Acquisition and Retention: Expert use of Adobe Analytics for campaign optimisation and personalised marketing allows businesses to not only acquire new customers effectively but also retain and nurture existing customers, driving long-term business success.
    </p>
          </div>`,
  },
  category4: {
    level1: `<div>
    <p>
    1. Bounce Rate Reduction: By implementing basic website performance tracking, businesses can identify areas of their website that contribute to high bounce rates and make improvements to reduce user drop-offs.
    </p>
    <p>
    2. Improved Time on Site: Tracking basic performance metrics enables businesses to understand user engagement and identify opportunities to optimise content or design elements to encourage users to spend more time on the site.
    </p>
    <p>
    3. Increased Page Views: By monitoring page views and user behavior, businesses can identify popular content or pages and take steps to promote this content, leading to increased page views and overall website engagement.
    </p>
           
              </div>`,
    level2: `<div>
    <p>
    1. Conversion Rate Improvement: By implementing intermediate website performance tracking, businesses can identify areas of their website that contribute to low conversion rates and optimise these areas to improve overall conversion rates.
    </p>
    <p>
    2. Increased User Engagement: Tracking intermediate performance metrics enables businesses to understand user engagement more thoroughly and identify opportunities to optimise content, design elements, or website functionality, resulting in higher user engagement.
    </p>
    <p>
    3. Reduced Cart Abandonment Rate: By monitoring user behavior and identifying potential issues within the conversion funnel, businesses can take steps to optimise the checkout process, leading to a reduction in cart abandonment rates and increased revenue.
    </p>
    </div>`,
    level3: `<div>
    <p>
    1. Exceptional Conversion Rate Improvement: By implementing advanced website performance tracking, businesses can pinpoint areas of their website that contribute to low conversion rates and optimise these areas with high precision, resulting in exceptional overall conversion rate improvements.
    </p>
    <p>
    2. Superior User Engagement: Tracking advanced performance metrics enables businesses to understand user engagement at a deeper level and identify opportunities to optimise content, design elements, or website functionality, resulting in significantly higher user engagement.
    </p>
    <p>
    3. Optimised Average Order Value (AOV): By monitoring user behavior and identifying opportunities to optimise product bundling, upselling, and cross-selling strategies, businesses can increase their average order value, leading to increased revenue.
    </p>
           
              </div>`,
    level4: `<div>
    <p>
    1. Unprecedented Conversion Rate Improvement: By implementing expert website performance tracking, businesses can precisely identify areas of their website that contribute to low conversion rates and optimise these areas with unmatched accuracy, resulting in unprecedented overall conversion rate improvements.
    </p>
    <p>
    2. Exceptional User Engagement: Tracking expert performance metrics enables businesses to understand user engagement at the deepest level and identify opportunities to optimise content, design elements, or website functionality, resulting in unparalleled user engagement.
    </p>
    <p>
    3. Optimised Customer Lifetime Value (CLV): By monitoring user behavior and identifying opportunities to improve customer retention, upselling, and cross-selling strategies, businesses can increase their customer lifetime value, leading to long-term revenue growth.
    </p>
    </div>`,
    level5: `<div>
    <p>
    1. Unrivaled Conversion Rate Improvement: By implementing expert website performance tracking, businesses can precisely identify and optimise areas of their website that contribute to low conversion rates, resulting in unrivaled overall conversion rate improvements.
    </p>
    <p>
    2. Exceptional User Engagement and Retention: Tracking expert performance metrics enables businesses to understand user engagement at the deepest level, optimise content, design elements, and website functionality, and create personalised experiences that result in unparalleled user engagement and customer retention.
    </p>
    <p>
    3. Optimised Customer Lifetime Value (CLV): By monitoring user behavior and identifying opportunities to improve customer retention, upselling, and cross-selling strategies, businesses can maximize their customer lifetime value, leading to long-term revenue growth and market leadership.
    </p>
    <p>
    4. Personalisation-Driven Competitive Advantage: Expert use of Adobe Analytics for personalisation and optimisation allows businesses to create unique, data-driven experiences for their customers, resulting in a strong competitive advantage and fostering customer loyalty.
    </p>   
          </div>`,
  },
  category5: {
    level1: `<div>
    <p>
    1. Data Accuracy: With a basic data governance structure in place, businesses can reduce the likelihood of data-related errors and improve the accuracy of their data, leading to better decision-making and improved performance.
    </p>
    <p>
    2. Data Consistency: Establishing standard naming conventions and data formats ensures that data is consistent across different teams and tools, making it easier for businesses to analyse and derive insights from their data.
    </p>
    <p>
    3. Time-to-Insight: By improving data quality and streamlining data management processes, businesses can reduce the time it takes to derive insights from their data, allowing them to respond more quickly to market changes and customer needs.
    </p>
           
              </div>`,
    level2: `<div>
    <p>
    1. Data Completeness: With an intermediate data layer in place, businesses can collect more data points and track a wider range of user interactions, resulting in a more complete view of user behavior and website performance.
    </p>
    <p>
    2. Data Integration Quality: Implementing an intermediate data layer improves the quality of data integration across different platforms and tools, ensuring that businesses can derive accurate and actionable insights from their data.
    </p>
    <p>
    3. Data-Driven Decision-Making: By capturing more detailed data and insights, businesses can make better-informed decisions based on data, leading to improved performance and increased return on investment for their analytics efforts.
    </p>
           
              </div>`,
    level3: `<div>
    <p>
    1. User Engagement: By leveraging advanced data layer capabilities for personalisation and segmentation, businesses can improve user engagement metrics, such as time spent on site, pages per visit, and bounce rate.
    </p>
    <p>
    2. Conversion Rate: Using the advanced data layer to personalise and segment user experiences can lead to increased conversions, such as newsletter sign-ups, product purchases, or other desired actions.
    </p>
    <p>
    3. Data-Driven Decision-Making Efficiency: With advanced analytics capabilities and real-time data collection, businesses can make more informed decisions more quickly, leading to improved efficiency and better overall performance.
    </p>
           
              </div>`,
    level4: `<div>
    <p>
    1. Customer Lifetime Value (CLV): By leveraging an Optimised data layer for hyper-personalisation and advanced optimisation, businesses can increase customer satisfaction and loyalty, leading to higher customer lifetime value.
    </p>
    <p>
    2. Return on Investment (ROI): Improved data quality and management enabled by an Optimised data layer contribute to better decision-making and more effective optimisation efforts, leading to increased return on investment for analytics initiatives.
    </p>
    <p>
    3. Data Quality and Accuracy: Implementing an Optimised data layer helps businesses ensure high-quality and accurate data, which is essential for deriving actionable insights and making data-driven decisions.
    </p>
           
              </div>`,
    level5: `<div>
    <p>
    1. Data Quality Excellence: Implementing expert-level data governance practices results in exceptional data quality, which is crucial for deriving accurate insights and making informed, data-driven decisions that drive business growth.
    </p>
    <p>
    2. Reduced Data-Related Risks: Expert data governance and privacy practices minimize the risk of data breaches, non-compliance, and potential reputational damage, ensuring the company's long-term stability and success.
    </p>
    <p>
    3. Customer Trust and Loyalty: By maintaining robust privacy and compliance management, businesses can demonstrate their commitment to protecting customer data, fostering trust, and building customer loyalty.
    </p>
    <p>
    4. Streamlined Data Management Processes: Expert-level data governance practices lead to streamlined data management processes, reducing the likelihood of data errors and inefficiencies, and enabling businesses to focus on deriving actionable insights and driving growth.
    </p>
          </div>`,
  },
};

export const exampleUseCases = {
  category1: {
    level1: `<div>
                  <p>
                  N/A (No tracking)
                  </p>
                  <p>
                  N/A (No integration)
                  </p>
                  <p>
                  N/A (No integration with non-Adobe tools)
                  </p>
              </div>`,
    level2: `<div>
                  <p>
                  A small business owner tracks basic website traffic metrics to identify popular pages and adjust content accordingly.
                  </p>
                  <p>
                  A content manager uses Adobe Analytics data to identify the best-performing articles and optimise the content creation process.
                  </p>
                  <p>
                  A sales manager uses CRM integration to understand the correlation between customer behaviour on the website and sales performance.
                  </p>
              </div>`,
    level3: `<div> 
                  <p> A marketing manager tracks user behaviour across multiple channels (e.g., web, mobile, social) to optimise campaigns. </p>
                  <p>A digital strategist integrates Adobe Analytics with other Adobe tools to gain a comprehensive view of user behaviour and optimise user journeys.</p>
                  <p>An operations manager integrates ERP and CRM systems with Adobe Analytics for a comprehensive view of sales and inventory data. </p>
            </div>`,
    level4: `<div>
                  <p> An e-commerce company tracks user behaviour across their website and customizes product recommendations based on the data. </p>
                  <p> A large organization optimises the integration of multiple Adobe tools to improve data flow and user experience. </p>
                  <p> A data-driven company integrates Adobe Analytics with various third-party tools to gain a comprehensive view of their users and operations. </p>
              </div>`,
    level5: `<div>
                  <p>
                  An online retailer uses Adobe Analytics to create personalised experiences for visitors based on their browsing history and preferences.
                  </p>
                  <p>
                  A large enterprise fully integrates the Adobe ecosystem to maximise data utilisation and create seamless user experiences.
                  </p>
                  <p>
                  A multinational corporation integrates Adobe Analytics with non-Adobe tools to create a holistic data analysis ecosystem.
                  </p>
            </div>`,
  },
  category2: {
    level1: `<div>
                  <p>
                  N/A (No tracking)
                  </p>
              </div>`,
    level2: `<div>
    <p>
    A blog manager tracks the number of daily visitors to monitor the popularity of the blog.
    </p>
    <p>
    A small business tracks the number of users who visit the "Contact Us" page.
    </p>
    <p>
    An online store tracks product views to identify which products are most popular.
    </p>
              </div>`,
    level3: `<div>
    <p>
    A digital marketer measures conversion rates from different channels and optimises campaigns.
    </p>
    <p>
    A product manager tracks user interactions with a new feature to measure its effectiveness.
    </p>
    <p>
    An e-commerce site tracks revenue and order data to analyse sales performance.
    </p>
              </div>`,
    level4: `<div>
    <p>
    A retailer tracks user behavior across multiple devices and personalises content accordingly.
    </p>
    <p>
    A SaaS company measures user adoption rates for new features and iterates based on feedback.
    </p>
    <p>
    An online marketplace analyses cart abandonment data and optimises the checkout process.
    </p>
              </div>`,
    level5: `<div>
    <p>
    A global enterprise uses real-time tracking to monitor performance across multiple regions.
    </p>
    <p>
    A travel agency analyses user behavior and optimises the booking flow for higher conversions.
    </p>
    <p>
    An e-commerce giant tracks and optimises the complete customer journey from acquisition to retention.
    </p>
          </div>`,
  },
  category3: {
    level1: `<div>
    <p>
    N/A (No experimentation)
    </p>
              </div>`,
    level2: `<div>
    <p>
    A startup runs simple A/B tests to compare two variations of a landing page.
    </p>
    <p>
    A marketing team tests different headlines to see which has the highest click-through rate.
    </p>
    <p>
    A small business tests two different pricing models on its website.
    </p>
              </div>`,
    level3: `<div>
    <p>
    An e-commerce site uses multivariate testing to optimise product page layouts.
    </p>
    <p>
    A SaaS company tests multiple onboarding flows to improve user experience and retention.
    </p>
    <p>
    A digital publisher tests different ad placements to increase ad revenue.
    </p>
              </div>`,
    level4: `<div>
    <p>
    A travel booking site runs personalisation experiments to display tailored content to visitors.
    </p>
    <p>
    A subscription-based service tests various incentive offers to increase customer lifetime value.
    </p>
    <p>
    A mobile app developer runs experiments to optimise in-app purchase conversions.
    </p>
              </div>`,
    level5: `<div>
    <p>
    A large enterprise runs continuous, automated experiments to optimise its entire digital presence.
    </p>
    <p>
    A tech giant tests and optimises the user experience across multiple devices and platforms.
    </p>
    <p>
    A multinational e-commerce company uses advanced experimentation to optimise its global supply chain.
    </p>
          </div>`,
  },
  category4: {
    level1: `<div>
    <p>
    N/A (No culture of experimentation)
    </p>
              </div>`,
    level2: `<div>
    <p>
    A small team within a company starts to advocate for experimentation and begins to share results.
    </p>
    <p>
    A startup founder encourages experimentation to quickly validate product ideas.
    </p>
    <p>
    A company begins to document and share results of experiments with the wider organization.
    </p>
              </div>`,
    level3: `<div>
    <p>
    A company establishes an experimentation center of excellence to guide other teams.
    </p>
    <p>
    A medium-sized company develops internal training and workshops on experimentation best practices.
    </p>
    <p>
    Managers across the company prioritize and support experimentation initiatives in their teams.
    </p>
              </div>`,
    level4: `<div>
    <p>
    A large organization integrates experimentation into its strategic planning process.
    </p>
    <p>
    A company fosters a culture of experimentation by creating executive sponsorship for key initiatives.
    </p>
    <p>
    An organization uses experimentation to inform decision-making at all levels of the business.
    </p>
              </div>`,
    level5: `<div>
    <p>
    A global enterprise has a mature culture of experimentation embedded in every aspect of the business.
    </p>
    <p>
    A leading tech company uses experimentation as a competitive advantage and a key driver of innovation.
    </p>
    <p>
    A large corporation prioritizes experimentation in its performance management and career development systems.
    </p>
          </div>`,
  },
  category5: {
    level1: `<div>
    <p>
    N/A (No data-driven decision-making)
    </p>
              </div>`,
    level2: `<div>
    <p>
    A small business starts to collect data and manually analyse it for making basic decisions.
    </p>
    <p>
    A marketing team begins to use data to optimise its advertising campaigns.
    </p>
    <p>
    A company uses basic data analysis tools to track sales and customer behavior.
    </p>
              </div>`,
    level3: `<div>
    <p>
    A medium-sized company invests in data analysis tools to support decision-making across teams.
    
    
    </p>
    <p>
    A company implements a data-driven decision-making training program for employees.
    </p>
    <p>
    Managers use data to support decision-making and guide team priorities.
    </p>
              </div>`,
    level4: `<div>
    <p>
    A large organization establishes a data-driven culture where data informs strategic planning.
    </p>
    <p>
    An enterprise-level company integrates data-driven decision-making into performance evaluations.
    </p>
    <p>
    A corporation leverages advanced analytics to guide resource allocation and investment decisions.
    </p>
              </div>`,
    level5: `<div>
    <p>
    A global enterprise fully embraces data-driven decision-making in every aspect of the business.


    </p>
    <p>
    A leading tech company consistently innovates using data-driven insights as a competitive advantage.
    </p>
    <p>
    A large corporation prioritizes data-driven decision-making in its talent acquisition and retention strategies.
    </p>
          </div>`,
  },
};

export const subCategories = {
  category1: {
    section1: {
      title: "Adobe Analytics Implementation",
      level1: {
        subtitle: "Essential Tracking Foundations",
        text: "Implement basic tracking on critical pages, capturing limited data points and custom events, and establish consistent naming conventions and basic reporting capabilities.",
      },
      level2: {
        subtitle: "Expanded Tracking & Reporting",
        text: "Extend tracking to most pages, capture broader data points, improve custom events tracking, enhance reporting capabilities, and implement continuous improvement processes.",
      },
      level3: {
        subtitle: "Advanced Analytics & Insights",
        text: "Implement comprehensive tracking across all pages, capture detailed data points, track advanced custom events, and utilise advanced segmentation and calculated metrics for in-depth analysis.",
      },
      level4: {
        subtitle: "Optimised Tracking & Performance",
        text: "Develop an optimised tracking strategy focused on KPIs and critical user flows, ensure granular data capture, automate data validation, and provide expert training and documentation for continuous improvement.",
      },
      level5: {
        subtitle: "Pioneer in-depth and predictive analytics",
        text: "Leverage data for predictive modeling and forecasting. Establish industry-leading benchmarks.",
      },
    },
    section2: {
      title: "Adobe tools integration",
      level1: {
        subtitle: "Adobe Tool Integration Foundations",
        text: "Establish basic integration with a single Adobe tool, identify simple use cases, and provide internal training on the integration process for data exchange and reporting.",
      },
      level2: {
        subtitle: "Multi-Tool Integration & Advanced Use Cases",
        text: "Integrate multiple Adobe tools, develop advanced use cases, create cross-tool reports, and implement data governance policies and extensive training for better collaboration and decision-making.",
      },
      level3: {
        subtitle: "Holistic Adobe Ecosystem & Optimisation",
        text: "Achieve seamless integration of Adobe Analytics with multiple Adobe tools, implement cross-tool advanced use cases, establish robust data governance, and foster a culture of collaboration and continuous improvement.",
      },
      level4: {
        subtitle: "Fully Integrated Adobe Ecosystem & Data-Driven Excellence",
        text: "Establish a fully integrated Adobe ecosystem, leverage data-driven decision-making, harness advanced personalisation and targeting capabilities, and continuously optimise tool functionality for sustainable business growth.",
      },
      level5: {
        subtitle:
          "Lead in implementing and leveraging cutting-edge Adobe features",
        text: "Regularly participate in Adobe's beta programs for early access to new features. Foster a culture of continuous learning and innovation.",
      },
    },
    section3: {
      title: "Non-Adobe tools integration",
      level1: {
        subtitle: "Non-Adobe Tool Integration Foundations",
        text: "Establish basic data exchange with a single non-Adobe tool, identify basic use cases, provide internal training, and assess the benefits and potential improvements of the integration.",
      },
      level2: {
        subtitle: "Multiple Non-Adobe Tool Integration & Custom Use Cases",
        text: "Integrate multiple non-Adobe tools, set up bi-directional data flow, identify custom use cases, provide advanced training, and foster cross-functional collaboration and continuous improvement.",
      },
      level3: {
        subtitle: "Advanced Integration Strategy & Governance",
        text: "Develop a comprehensive integration strategy, establish seamless bi-directional data flow, implement advanced data mapping, define integration governance policies, and provide in-depth training and support.",
      },
      level4: {
        subtitle: "Fully Integrated Ecosystem & Cross-Platform Insights",
        text: "Create a comprehensive integration strategy for a fully integrated ecosystem with non-Adobe tools, develop custom integrations and APIs, leverage cross-platform insights, and foster a culture of knowledge sharing and collaboration.",
      },
      level5: {
        subtitle:
          "Champion in-depth integrations and symbiotic relationships with non-Adobe tools",
        text: "Explore and leverage advanced data exchange capabilities. Lead in setting industry benchmarks for integrations.",
      },
    },
    section4: {
      title: "Mobile app tracking",
      level1: {
        subtitle: "Basic Mobile App Tracking and Analysis",
        text: "Implement basic mobile app tracking, collect essential data points, create basic reports, establish minimal mobile-web integration, and perform basic user behaviour analysis.",
      },
      level2: {
        subtitle: "Enhanced Mobile App Tracking and Advanced Analysis",
        text: "Implement comprehensive tracking across mobile apps, collect enhanced data points, establish stronger integration between mobile app and web data, and perform advanced user behavior analysis and A/B testing.",
      },
      level3: {
        subtitle: "Cross-Platform Tracking and Advanced Integration",
        text: "Establish holistic cross-platform tracking, collect comprehensive data points, ensure seamless integration of web and mobile app data, perform real-time reporting and analysis, and create cross-platform user segments.",
      },
      level4: {
        subtitle:
          "Optimised Cross-Platform Tracking and Continuous Improvement",
        text: "Implement optimised holistic cross-platform tracking, focus on capturing critical KPIs, achieve seamless integration, perform real-time reporting and analysis, and continuously optimise the tracking setup based on evolving business goals and user experience requirements.",
      },
      level5: {
        subtitle:
          "Be at the forefront of cross-platform analytics and personalisation",
        text: "Lead in unifying user data and personalisation across platforms. Push the boundaries in cross-platform analytics",
      },
    },
    section5: {
      title: "Data Layer setup",
      level1: {
        subtitle: "Basic Data Layer Implementation and Management",
        text: "Set up a basic Data Layer, identify key data points, ensure structured data capture, apply consistent naming conventions, perform basic data validation, and maintain clear documentation.",
      },
      level2: {
        subtitle: "Intermediate Data Layer Enhancement and Validation",
        text: "Enhance the Data Layer by capturing a wider range of data points, prioritize additional data points, improve data structuring, perform regular data validation, and provide ongoing training and support.",
      },
      level3: {
        subtitle: "Advanced Data Layer Setup and Optimisation",
        text: "Implement a comprehensive Data Layer, continuously evaluate data points, establish a robust data structure, utilise advanced tagging and tracking techniques, and maintain extensive documentation.",
      },
      level4: {
        subtitle:
          "Streamlined Data Layer Management and Continuous Improvement",
        text: "Focus on capturing critical data points, implement an optimised data structure, utilise advanced tracking techniques, ensure data quality assurance, continuously monitor performance, and foster cross-functional collaboration.",
      },
      level5: {
        subtitle: "Drive innovation in Data Layer management and utilisation",
        text: "Pioneering new ways to leverage the Data Layer for deeper insights. Stay ahead of industry trends in Data Layer management.",
      },
    },
  },

  /////BREAK BREAK BREAK

  category2: {
    section1: {
      title: "Report generation frequency",
      level1: {
        subtitle: "Basic Adobe Analytics Reporting",
        text: "Focus on identifying report needs, basic report generation, data interpretation, sharing findings, identifying reporting gaps, and encouraging continuous learning.",
      },
      level2: {
        subtitle: "Intermediate Reporting and Performance Tracking",
        text: "Establish a reporting schedule, create custom reports, automate report generation, foster cross-functional collaboration, monitor performance, and expand team skillsets.",
      },
      level3: {
        subtitle: "Advanced Reporting and In-Depth Analysis",
        text: "Implement a weekly reporting schedule, create comprehensive custom reports, distribute reports automatically, share cross-department insights, optimise reporting processes, and encourage skill development.",
      },
      level4: {
        subtitle: "Real-Time Reporting and Continuous Optimisation",
        text: "Develop a daily reporting process, create advanced custom reports, provide real-time report access, integrate data from multiple sources, leverage advanced analytics, and foster a collaborative environment.",
      },
    },
    section2: {
      title: "Workspace proficiency",
      level1: {
        subtitle: "Basic Adobe Analytics Workspace Proficiency",
        text: "Focus on basic navigation, simple report creation, basic visualisation use, limited customisation, and skill development.",
      },
      level2: {
        subtitle: "Intermediate Workspace Proficiency",
        text: "Emphasize custom report creation, multiple visualisations, data segmentation, advanced customisation, data integration, and continuous learning.",
      },
      level3: {
        subtitle: "Advanced Workspace Proficiency",
        text: "Concentrate on comprehensive reports, advanced visualisations, custom segments, multiple data sources, advanced troubleshooting, collaboration, and training and support.",
      },
      level4: {
        subtitle: "Expert Adobe Analytics Workspace Proficiency",
        text: "Prioritize highly customised reports, interactive visualisations, complex segments, data blending, predictive insights, expert troubleshooting, leadership, and continuous learning.",
      },
    },
    section3: {
      title: "Calculated metrics/segments",
      level1: {
        subtitle: "Basic Calculated Metrics and Segments Proficiency",
        text: "Focus on awareness, basic usage, simple analysis, standard metrics application, basic segment application, limited customisation, and skill improvement.",
      },
      level2: {
        subtitle: "Intermediate Calculated Metrics and Segments Proficiency",
        text: "Emphasize custom calculated metrics, custom segments, moderate usage, custom metric application, custom segment application, customisation experience, and continuous skill development.",
      },
      level3: {
        subtitle: "Advanced Calculated Metrics and Segments Proficiency",
        text: "Concentrate on multiple custom metrics, complex segments, advanced usage, integration, tailored insights, data granularity, analytical depth, and mastery.",
      },
      level4: {
        subtitle: "Expert Calculated Metrics and Segments Proficiency",
        text: "Prioritize an extensive library of custom metrics and segments, advanced segments, expert usage, seamless integration, actionable insights, data-driven decisions, advanced analytical techniques, thought leadership, and expertise sharing.",
      },
    },
    section4: {
      title: "Anomaly detection/alerts",
      level1: {
        subtitle: "Basic Mobile App Tracking and Analysis",
        text: "Implement basic mobile app tracking, collect essential data points, create basic reports, establish minimal mobile-web integration, and perform basic user behavior analysis.",
      },
      level2: {
        subtitle: "Expanded Tracking & Reporting",
        text: "Extend tracking to most pages, capture broader data points, improve custom events tracking, enhance reporting capabilities, and implement continuous improvement processes.",
      },
      level3: {
        subtitle: "Cross-Platform Tracking and Advanced Integration",
        text: "Establish holistic cross-platform tracking, collect comprehensive data points, ensure seamless integration of web and mobile app data, perform real-time reporting and analysis, and create cross-platform user segments.",
      },
      level4: {
        subtitle:
          "Optimised Cross-Platform Tracking and Continuous Improvement",
        text: "Implement Optimised holistic cross-platform tracking, focus on capturing critical KPIs, achieve seamless integration, perform real-time reporting and analysis, and continuously optimise the tracking setup based on evolving business goals and user experience requirements.",
      },
    },
    section5: {
      title: "Funnel analysis/optimisation",
      level1: {
        subtitle:
          "Basic Funnel Analysis and Conversion Optimisation Proficiency",
        text: "Focus on basic usage, standard funnels, simple insights, minimal optimisation efforts, basic conversion metrics, limited understanding, occasional analysis, basic reporting, and limited customisation.",
      },
      level2: {
        subtitle:
          "Intermediate Funnel Analysis and Conversion Optimisation Proficiency",
        text: "Emphasize moderate usage, custom funnels, deeper insights, regular optimisation efforts, custom conversion metrics, growing understanding, regular analysis, custom reporting, and moderate customisation.",
      },
      level3: {
        subtitle:
          "Advanced Funnel Analysis and Conversion Optimisation Proficiency",
        text: "Concentrate on advanced usage, in-depth funnels, comprehensive insights, continuous optimisation, advanced conversion metrics, expert understanding, frequent analysis, advanced reporting, and high customisation.",
      },
      level4: {
        subtitle:
          "Expert Funnel Analysis and Conversion Optimisation Proficiency",
        text: "Prioritize granular usage, advanced testing, actionable insights, iterative optimisation, dynamic conversion metrics, deep expertise, constant analysis, sophisticated reporting, and maximum customisation.",
      },
    },
  },

  // BREAK BREAK BREAK
  category3: {
    section1: {
      title: "Marketing Channels understanding",
      level1: {
        subtitle: "Basic Marketing Channels Proficiency",
        text: "Focus on basic understanding, default channels, limited customisation, basic reporting, entry-level knowledge, minimal rules, limited analysis, basic attribution, and beginner's proficiency.",
      },
      level2: {
        subtitle: "Intermediate Marketing Channels Proficiency",
        text: "Emphasize intermediate understanding, custom channels, moderate customisation, custom reporting, applied knowledge, multiple rules, in-depth analysis, custom attribution, and intermediate proficiency.",
      },
      level3: {
        subtitle: "Advanced Marketing Channels Proficiency",
        text: "Concentrate on advanced understanding, granular channels, extensive customisation, comprehensive reporting, in-depth knowledge, complex rules, comprehensive analysis, advanced attribution, and advanced proficiency.",
      },
      level4: {
        subtitle: "Expert Marketing Channels Proficiency",
        text: "Prioritize expert understanding, Optimised channels, highly customised channels, advanced reporting, expert knowledge, sophisticated rules, data-driven analysis, expert attribution, and expert proficiency.",
      },
    },
    section2: {
      title: "Attribution models usage",
      level1: {
        subtitle: "Basic Attribution Models Proficiency",
        text: "Focus on basic understanding, basic models, limited application, awareness of concepts, minimal customisation, basic reporting, limited analysis, basic proficiency, improvement opportunities, and encouraging learning.",
      },
      level2: {
        subtitle: "Intermediate Attribution Models Proficiency",
        text: "Emphasize intermediate understanding, custom models, broader application, familiarity with concepts, moderate customisation, custom reporting, moderate analysis, intermediate proficiency, improvement opportunities, and encouraging learning.",
      },
      level3: {
        subtitle: "Advanced Attribution Models Proficiency",
        text: "Concentrate on advanced understanding, multi-touch models, comprehensive application, in-depth knowledge of concepts, advanced customisation, in-depth reporting, advanced analysis, advanced proficiency, continuous improvement, and encouraging innovation.",
      },
      level4: {
        subtitle: "Expert Attribution Models Proficiency",
        text: "Prioritize expert understanding, advanced multi-touch models, full-scope application, mastery of concepts, highly customised models, granular reporting, expert analysis, expert proficiency, optimisation for performance, and encouraging thought leadership.",
      },
    },
    section3: {
      title: "Campaign tracking/analysis",
      level1: {
        subtitle: "Basic Campaign Tracking and Analysis Proficiency",
        text: "Focus on basic understanding, default metrics, limited analysis, minimal customisation, simple reports, basic proficiency, minimal optimisation, scope for improvement, encouraging learning, and basic usage.",
      },
      level2: {
        subtitle: "Intermediate Campaign Tracking and Analysis Proficiency",
        text: "Emphasize moderate understanding, custom metrics, regular analysis, moderate customisation, custom reports, intermediate proficiency, some optimisation, continuous improvement, encouraging exploration, and moderate usage.",
      },
      level3: {
        subtitle: "Advanced Campaign Tracking and Analysis Proficiency",
        text: "Concentrate on advanced understanding, in-depth analysis, advanced customisation, comprehensive reports, advanced proficiency, continuous optimisation, proactive adjustments, testing and iteration, advanced usage, and skill development.",
      },
      level4: {
        subtitle: "Expert Campaign Tracking and Analysis Proficiency",
        text: "Prioritize expert understanding, granular analysis, real-time optimisation, ROI-focused, expert proficiency, automation, predictive insights, continuous testing, expert usage, and knowledge sharing.",
      },
    },
    section4: {
      title: "Paid campaign optimisation",
      level1: {
        subtitle: "Basic Paid Campaign Optimisation Proficiency",
        text: "Focus on basic understanding, standard metrics, minimal customisation, basic reports, limited analysis, occasional optimisation, basic proficiency, limited automation, basic usage, and skill improvement.",
      },
      level2: {
        subtitle: "Intermediate Paid Campaign Optimisation Proficiency",
        text: "Emphasize intermediate understanding, custom metrics, moderate customisation, custom reports, some analysis, regular optimisation, intermediate proficiency, some automation, moderate usage, and continued skill development.",
      },
      level3: {
        subtitle: "Advanced Paid Campaign Optimisation Proficiency",
        text: "Concentrate on advanced understanding, data-driven insights, extensive customisation, in-depth reports, advanced analysis, continuous optimisation, advanced proficiency, automation usage, advanced usage, and mastery development.",
      },
      level4: {
        subtitle: "Expert Paid Campaign Optimisation Proficiency",
        text: "Prioritize expert understanding, granular insights, comprehensive customisation, real-time reports, granular analysis, real-time optimisation, expert proficiency, advanced automation, expert usage, and continuous improvement.",
      },
    },
    section5: {
      title: "Email performance measurement",
      level1: {
        subtitle: "Basic Email Marketing Performance Measurement Proficiency",
        text: "Focus on minimal email tracking, basic understanding, limited customisation, standard metrics, basic reporting, basic analysis, limited optimisation, beginner proficiency, basic usage, and skill development.",
      },
      level2: {
        subtitle:
          "Intermediate Email Marketing Performance Measurement Proficiency",
        text: "Emphasize moderate email tracking, intermediate understanding, customisation, custom metrics, custom reporting, moderate analysis, some optimisation, intermediate proficiency, moderate usage, and skill improvement.",
      },
      level3: {
        subtitle:
          "Advanced Email Marketing Performance Measurement Proficiency",
        text: "Concentrate on advanced campaign tracking, in-depth analysis, continuous optimisation, advanced proficiency, custom dimensions, cross-channel tracking, ROI measurement, attribution modeling, advanced usage, and skill mastery.",
      },
      level4: {
        subtitle: "Expert Email Marketing Performance Measurement Proficiency",
        text: "Prioritize granular email tracking, expert understanding, advanced customisation, advanced metrics, comprehensive reporting, in-depth analysis, continuous optimisation, expert proficiency, advanced usage, and skill excellence.",
      },
    },
  },

  // BREAK BREAK BREAK
  category4: {
    section1: {
      title: "Audience segmentation usage",
      level1: {
        subtitle: "Basic Audience Segmentation Proficiency",
        text: "Focus on segmentation awareness, predefined segments, basic understanding, simple segmentation, standard reports, limited insights, basic proficiency, usage, basic skillset, and growth potential.",
      },
      level2: {
        subtitle: "Intermediate Audience Segmentation Proficiency",
        text: "Emphasize custom segments, intermediate understanding, customisation, enhanced insights, multiple criteria, data-driven analysis, intermediate proficiency, increased usage, skill improvement, and continued growth.",
      },
      level3: {
        subtitle: "Advanced Audience Segmentation Proficiency",
        text: "Concentrate on advanced segmentation, dynamic segments, cross-platform data, in-depth insights, integration with other tools, proficient usage, personalisation, real-time activation, advanced skillset, and ongoing optimisation.",
      },
      level4: {
        subtitle: "Expert Audience Segmentation Proficiency",
        text: "Prioritize granular insights, real-time activation, cross-platform data, predictive modeling, expert proficiency, continuous optimisation, advanced personalisation, integration with other tools, expert skillset, and innovation.",
      },
    },
    section2: {
      title: "Adobe Audience Manager",
      level1: {
        subtitle: "Basic Adobe Audience Manager Proficiency",
        text: "Focus on Adobe Audience Manager awareness, simple audience creation, limited activation, basic user interface understanding, introductory training, low-level integrations, basic reporting, beginner proficiency, room for improvement, and ongoing learning.",
      },
      level2: {
        subtitle: "Intermediate Adobe Audience Manager Proficiency",
        text: "Emphasize custom audience creation, multi-channel activation, intermediate user interface understanding, intermediate training, integrations, custom reporting, intermediate proficiency, data-driven strategies, continuous improvement, and ongoing learning.",
      },
      level3: {
        subtitle: "Advanced Adobe Audience Manager Proficiency",
        text: "Concentrate on complex audience creation, advanced activation, expert user interface understanding, advanced training, comprehensive integrations, in-depth reporting, advanced proficiency, predictive modeling, real-time activation, and continuous optimisation.",
      },
      level4: {
        subtitle: "Expert Adobe Audience Manager Proficiency",
        text: "Prioritize data-driven audience creation, advanced cross-channel activation, expert proficiency, continued education, full-suite integrations, granular reporting, real-time optimisation, predictive modeling and AI, proactive audience management, and commitment to excellence.",
      },
    },
    section3: {
      title: "Adobe Target personalisation",
      level1: {
        subtitle: "Basic Adobe Target Proficiency",
        text: "Focus on basic understanding, simple rules, limited testing, basic training, default settings, manual implementation, basic performance tracking, minimal optimisation, limited audience targeting, and basic user experience.",
      },
      level2: {
        subtitle: "Intermediate Adobe Target Proficiency",
        text: "Emphasize custom rules, A/B testing, intermediate understanding, advanced training, custom settings, automation, performance tracking, optimisation efforts, targeted audience segments, and improved user experience.",
      },
      level3: {
        subtitle: "Advanced Adobe Target Proficiency",
        text: "Concentrate on advanced understanding, AI-driven personalisation, extensive A/B and multivariate testing, dynamic content, complex targeting, integration with other Adobe tools, data-driven insights, comprehensive performance tracking, continuous optimisation, and highly personalised user experience.",
      },
      level4: {
        subtitle: "Expert Adobe Target Proficiency",
        text: "Prioritize expert understanding, real-time optimisation, advanced testing, cross-channel personalisation, predictive modeling, granular audience targeting, integration with multiple data sources, comprehensive performance analysis, continuous improvement, and exceptional user experience.",
      },
    },
    section4: {
      title: "Personalised experiences creation",
      level1: {
        subtitle: "Basic Adobe Analytics Personalisation Proficiency",
        text: "Focus on minimal personalisation, limited data points, simple rules, no automation, basic tracking, static content, limited data integration, minimal impact, no continuous optimisation, and basic customer experiences.",
      },
      level2: {
        subtitle: "Intermediate Adobe Analytics Personalisation Proficiency",
        text: "Emphasize moderate personalisation, custom data points, custom rules, some automation, tracking custom metrics, dynamic content, integration with multiple data sources, improved impact, some continuous optimisation, and enhanced customer experiences.",
      },
      level3: {
        subtitle: "Advanced Adobe Analytics Personalisation Proficiency",
        text: "Concentrate on advanced personalisation, multiple data points, complex rules, extensive automation, in-depth tracking, highly dynamic content, integration with rich data sources, significant impact, continuous optimisation, and data-driven customer experiences.",
      },
      level4: {
        subtitle: "Expert Adobe Analytics Personalisation Proficiency",
        text: "Prioritize granular personalisation, real-time data points, advanced rules and AI, real-time automation, real-time performance tracking, contextual content, cross-platform data integration, exceptional impact, real-time optimisation, and highly personalised experiences.",
      },
    },
    section5: {
      title: "Customer journey optimisation",
      level1: {
        subtitle: "Basic Customer Journey Proficiency",
        text: "Focus on basic journey analysis, limited touchpoints, minimal optimisation, limited data sources, basic reports, limited cross-channel integration, basic insights, minimal personalisation, limited automation, and basic customer experience.",
      },
      level2: {
        subtitle: "Intermediate Customer Journey Proficiency",
        text: "Emphasize moderate journey analysis, custom touchpoints, some optimisation, multiple data sources, custom reports, some cross-channel integration, moderate insights, moderate personalisation, some automation, and improved customer experience.",
      },
      level3: {
        subtitle: "Advanced Customer Journey Proficiency",
        text: "Concentrate on advanced journey analysis, cross-platform touchpoints, continuous optimisation, rich data sources, comprehensive reports, extensive cross-channel integration, advanced insights, extensive personalisation, advanced automation, and enhanced customer experience.",
      },
      level4: {
        subtitle: "Expert Customer Journey Proficiency",
        text: "Prioritize expert journey analysis, granular touchpoints, real-time optimisation, cross-platform data, in-depth reports, seamless cross-channel integration, granular insights, dynamic personalisation, predictive modeling, and exceptional customer experience.",
      },
    },
  },
  // BREAK BREAK BREAK
  category5: {
    section1: {
      title: "Data governance policies",
      level1: {
        subtitle: "Basic Data Governance Proficiency",
        text: "Focus on basic data storage, access control, governance awareness, documentation, training, enforcement, data protection, audits, compliance, and minimal data quality checks.",
      },
      level2: {
        subtitle: "Intermediate Data Governance Proficiency",
        text: "Emphasize intermediate data storage policies, custom access control, data governance plans, detailed documentation, regular training, enforcement of policies, robust data protection, regular audits, industry-standard compliance, and regular data quality checks.",
      },
      level3: {
        subtitle: "Advanced Data Governance Proficiency",
        text: "Concentrate on comprehensive data storage policies, granular access control, well-defined data governance strategies, in-depth documentation, continuous training, strict enforcement, advanced data protection, regular audits and enforcement, full regulatory compliance, and continuous data quality checks.",
      },
      level4: {
        subtitle: "Expert Data Governance Proficiency",
        text: "Prioritize comprehensive data governance frameworks, automated access control, data governance maturity, extensive documentation, advanced training and development, proactive enforcement, robust data protection and security, continuous audits and improvements, full regulatory compliance, and automated data quality monitoring.",
      },
    },
    section2: {
      title: "GDPR/CCPA compliance",
      level1: {
        subtitle: "Basic Data Privacy Proficiency",
        text: "Focus on basic data privacy settings, minimal compliance efforts, limited user consent management, awareness of regulations, and basic data protection measures in Adobe Analytics.",
      },
      level2: {
        subtitle: "Intermediate Data Privacy Proficiency",
        text: "Concentrate on custom data privacy settings, intermediate compliance efforts, custom user consent management, in-depth understanding of regulations, improved data protection measures, regular compliance monitoring, and user consent tracking in Adobe Analytics.",
      },
      level3: {
        subtitle: "Advanced Data Privacy Proficiency",
        text: "Prioritize advanced data privacy settings, comprehensive compliance efforts, robust user consent management, expert understanding of regulations, sophisticated data protection measures, continuous compliance monitoring, automated data deletion, and a proactive compliance approach in Adobe Analytics.",
      },
      level4: {
        subtitle: "Expert Data Privacy Proficiency",
        text: "Emphasize granular data privacy controls, holistic compliance efforts, advanced user consent management, complete mastery of regulations, cutting-edge data protection measures, real-time compliance monitoring, automated data deletion and management, proactive and adaptable compliance approach, continuous improvement in privacy practices, and integration of privacy practices across the organization in Adobe Analytics.",
      },
    },
    section3: {
      title: "User access management",
      level1: {
        subtitle: "Basic User Access Management Proficiency",
        text: "Focus on minimal user access control, basic user management, limited access levels, and occasional monitoring of user access and permissions in Adobe Analytics.",
      },
      level2: {
        subtitle: "Intermediate User Access Management Proficiency",
        text: "Concentrate on custom user access levels, structured user management, defined access levels based on roles and responsibilities, regular monitoring, and access level adjustments in Adobe Analytics.",
      },
      level3: {
        subtitle: "Advanced User Access Management Proficiency",
        text: "Prioritize granular user access control, advanced user management, role-based access levels, continuous monitoring, regular audits, and enforcement of access policies in Adobe Analytics.",
      },
      level4: {
        subtitle: "Expert User Access Management Proficiency",
        text: "Emphasize automated user access control, sophisticated user management, role- and project-based access levels, real-time monitoring, continuous audits, strict enforcement of access policies, and integration with identity management systems in Adobe Analytics",
      },
    },
    section4: {
      title: "Privacy-focused insights",
      level1: {
        subtitle: "Basic Privacy-Focused Analytics Proficiency",
        text: "Focus on basic privacy, limited privacy controls, and minimal privacy-focused insights when using Adobe Analytics.",
      },
      level2: {
        subtitle: "Intermediate Privacy-Focused Analytics Proficiency",
        text: "Concentrate on moderate privacy focus, custom privacy controls, privacy-aware insights, and customer consent management in Adobe Analytics.",
      },
      level3: {
        subtitle: "Advanced Privacy-Focused Analytics Proficiency",
        text: "Prioritize access and permission management, granular access control, regular audits, and enforcement of user access and permission violations in Adobe Analytics.",
      },
      level4: {
        subtitle: "Expert Privacy-Focused Analytics Proficiency",
        text: "Emphasize strong privacy focus, granular privacy controls, ethical data usage, real-time monitoring, and a proactive approach to GDPR and CCPA compliance when using Adobe Analytics for insights.",
      },
    },
    section5: {
      title: "Data quality monitoring",
      level1: {
        subtitle: "Data Quality Kickstart",
        text: "Implement a structured approach to perform regular data quality checks and introduce basic data validation processes in Adobe Analytics.",
      },
      level2: {
        subtitle: "Data Quality Assurance",
        text: "Transition to continuous data quality checks, enhance data validation processes, and establish a proactive error resolution system in Adobe Analytics.",
      },
      level3: {
        subtitle: "Data Quality Automation",
        text: "Implement automated data quality checks, real-time data validation, and proactive error resolution while continuously monitoring data quality and accuracy in Adobe Analytics.",
      },
      level4: {
        subtitle: "Data Quality Excellence",
        text: "Establish strong data quality governance policies and practices to ensure a consistent, organization-wide approach to maintaining high-quality data in Adobe Analytics.",
      },
    },
  },
};

export const overallScoreInsights = {
  level1: {
    levelName: "Data Enthusiast",
    nextLevelText:
      "By moving to the next level, Insight Seeker, a company should be able to better track custom touchpoints, analyse specific user behaviors, and create simple segments. This improvement in capability allows for more targeted marketing, increased customer engagement, and a better understanding of the customer journey, ultimately leading to higher conversion rates and revenue growth.",
    currentLevelText:
      "At this level, a company is just beginning to use Adobe Analytics. The primary benefit lies in gaining access to basic insights and understanding of standard metrics. This capability allows for better decision-making based on data, improved tracking of basic user interactions, and a foundation for more advanced analytics in the future.",
  },
  level2: {
    levelName: "Insight Seeker",
    nextLevelText:
      "Progressing to the next level,Analytics Innovator, enables a company to integrate cross-platform data, analyse multiple data points, and utilise advanced segmentation techniques. These capabilities facilitate continuous optimisation of the customer journey across touchpoints, driving customer retention and loyalty, and ultimately leading to improved long-term revenue growth.",
    currentLevelText:
      "At this level, a company should be able to effectively track custom touchpoints, analyse specific user behaviors, and create simple segments. This improvement in capability enables more targeted marketing, increased customer engagement, and a better understanding of the customer journey, ultimately leading to higher conversion rates and revenue growth.",
  },
  level3: {
    levelName: "Analytics Innovater",
    nextLevelText:
      "By advancing to the next level, Data-Driven Trailblazer, a company gains the ability to leverage granular insights, predictive modeling, and real-time analytics for proactive customer journey optimisation. This level of capability empowers the company to stay ahead of industry trends, respond to customer needs more effectively, and achieve a competitive advantage, which translates into increased market share and sustained growth.",
    currentLevelText:
      "At this level, a company should be able to integrate cross-platform data, analyse multiple data points, and utilise advanced segmentation techniques. These capabilities facilitate continuous optimisation of the customer journey across touchpoints, driving customer retention and loyalty, and ultimately leading to improved long-term revenue growth.",
  },
  level4: {
    levelName: "Data-Driven Trailblazer",
    nextLevelText:
      "Nearing Analytics Luminary, signifies mastery of Adobe Analytics, with a strong focus on data privacy, customer consent, and ethical data usage. By achieving this level, a company not only optimises its use of Adobe Analytics but also becomes a thought leader within the industry. This leadership position attracts talent, fosters innovation, and should be able to lead to new business opportunities, partnerships, and long-term value creation.",
    currentLevelText:
      "Companies at this level should be able to leverage granular insights, predictive modeling, and real-time analytics for proactive customer journey optimisation. This level of capability empowers the company to stay ahead of industry trends, respond to customer needs more effectively, and achieve a competitive advantage, which translates into increased market share and sustained growth.",
  },
  level5: {
    levelName: "Analytics Luminary",
    nextLevelText:
      "At this level, a company has achieved the highest level of expertise in Adobe Analytics. The primary focus should be on maintaining this level of expertise and adapting to any future advancements in the platform. The business value of sustaining this level is the ability to continuously deliver exceptional customer experiences, drive innovation, and maintain a competitive edge in the market.",
    currentLevelText:
      "At the highest level of expertise, a company has achieved mastery of Adobe Analytics, with a strong focus on data privacy, customer consent, and ethical data usage. This level of capability allows the company to continuously deliver exceptional customer experiences, drive innovation, and maintain a competitive edge in the market. Moreover, the organization's thought leadership position should be able to attract talent, foster innovation, and lead to new business opportunities and partnerships.",
  },
};

export const sectionTitles = {
  category1: "Section 1: Data Collection and Integration",
  category2: "Section 2: Reporting and Analysis",
  category3: "Section 3: Attribution and Marketing",
  category4: "Section 4: Personalisation and Audience Segmentation",
  category5: "Section 5: Data Governance and Privacy",
};

export const sectionArray = [
  "section1",
  "section2",
  "section3",
  "section4",
  "section5",
];
