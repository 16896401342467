export const AWSconfig = {
  identityPoolId: process.env.REACT_APP_COGNITO_IDENTITYPOOLID,
  region: "eu-west-1",
  dynamoDBApiVersion: "2012-08-10",
};

// station10_password;

// station10_user;

export const freeTextQuestion = "freeTextQuestion";

const surveyQuestions = {
  q1: {
    name: "dataCollectionAndIntegrationQ1",
    title:
      "How well have you implemented Adobe Analytics tracking on your website?",
    weight: 0.25,
    choice1: "No tracking implemented",
    choice2: "Tracking on a few critical pages, limited data points",
    choice3: "Tracking on most pages with a good range of data points",
    choice4: "Comprehensive tracking across all pages and interactions",
    choice5:
      "Optimised tracking with a focus on critical KPIs, user flows, and events",
    category: "category1",
  },
  q2: {
    name: "dataCollectionAndIntegrationQ2",
    title:
      "How effectively have you integrated Adobe Analytics with other Adobe tools (e.g. Target, Experience Manager)?",
    weight: 0.25,
    choice1: "No integration with other Adobe tools",
    choice2: "Basic integration with one Adobe tool",
    choice3: "Integration with a few Adobe tools, limited functionality",
    choice4:
      "Integration with multiple Adobe tools, leveraging synergies and functionalities",
    choice5:
      "Fully integrated ecosystem, seamless data flow, and collaboration across Adobe tools",
    category: "category1",
  },
  q3: {
    name: "dataCollectionAndIntegrationQ3",
    title:
      "How well have you integrated Adobe Analytics with non-Adobe tools (e.g., CRM, ERP)?",
    weight: 0.2,
    choice1: "No integration with non-Adobe tools",
    choice2: "Basic integration with one non-Adobe tool, limited data exchange",
    choice3:
      "Integration with a few non-Adobe tools, some data synchronisation",
    choice4:
      "Integration with multiple non-Adobe tools, bi-directional data flow",
    choice5:
      "Fully integrated ecosystem with non-Adobe tools, seamless data flow, and cross-platform insights",
    category: "category1",
  },
  q4: {
    name: "dataCollectionAndIntegrationQ4",
    title: "Are you using Adobe Analytics to collect data from mobile apps?",
    weight: 0.15,
    choice1: "Not collecting any mobile app data",
    choice2: "Basic mobile tracking with limited data points",
    choice3:
      "Tracking data from both web and mobile apps with some integration",
    choice4:
      "Advanced mobile app tracking with comprehensive data points and integration",
    choice5:
      "Holistic cross-platform tracking, unified view of user behavior across web and app",
    category: "category1",
  },
  q5: {
    name: "dataCollectionAndIntegrationQ5",
    title:
      "How well have you set up Data Layer to capture and structure data for Adobe Analytics?",
    weight: 0.15,
    choice1: "No Data Layer implemented",
    choice2: "Basic Data Layer capturing a few data points",
    choice3:
      "Intermediate Data Layer with a range of data points and some structuring",
    choice4:
      "Advanced Data Layer capturing a wide range of data points and structured hierarchy",
    choice5:
      "Optimised Data Layer capturing critical data points, well-structured, and easy to maintain",
    category: "category1",
  },
  q6: {
    name: "reportingAndAnalysisQ1",
    title: "How frequently do you generate reports using Adobe Analytics?",
    weight: 0.25,
    choice1: "Never generate reports",
    choice2: "Rarely generate reports, as needed",
    choice3: "Generate monthly reports for basic insights",
    choice4: "Generate weekly reports for regular insights and analysis",
    choice5:
      "Generate daily reports for real-time decision-making and continuous optimisation",
    category: "category2",
  },
  q7: {
    name: "reportingAndAnalysisQ2",
    title:
      "How proficient are you in using Workspace for custom reports and analysis?",
    weight: 0.25,
    choice1: "Not familiar with Workspace",
    choice2: "Basic proficiency, can create simple reports",
    choice3:
      "Intermediate proficiency, can create custom reports with multiple visualisations",
    choice4:
      "Advanced proficiency, can create comprehensive reports with complex visualisations",
    choice5:
      "Create highly customised and interactive reports for advanced analysis",
    category: "category2",
  },
  q8: {
    name: "reportingAndAnalysisQ3",
    title: "Are you using calculated metrics and segments to derive insights?",
    weight: 0.175,
    choice1: "Not using calculated metrics or segments",
    choice2: "Basic usage, a few standard calculated metrics and segments",
    choice3:
      "Moderate usage, some custom calculated metrics and segments for specific analysis",
    choice4:
      "Advanced usage, multiple custom calculated metrics and segments for in-depth analysis",
    choice5:
      "Extensive library of custom calculated metrics and segments for granular insights",
    category: "category2",
  },
  q9: {
    name: "reportingAndAnalysisQ4",
    title:
      "How well do you utilise anomaly detection and alerts for monitoring performance?",
    weight: 0.1,
    choice1: "Not using anomaly detection or alerts",
    choice2: "Basic usage, set up a few alerts for critical KPIs",
    choice3:
      "Moderate usage, set up alerts and anomaly detection for multiple KPIs",
    choice4:
      "Advanced usage, proactive monitoring with anomaly detection and alerts on various metrics",
    choice5:
      "Extensive use of anomaly detection, automated alerts, and predictive insights for proactive decision-making",
    category: "category2",
  },
  q10: {
    name: "reportingAndAnalysisQ5",
    title:
      "Are you using Adobe Analytics for funnel analysis and conversion optimisation?",
    weight: 0.225,
    choice1: "Not using Adobe Analytics for funnel analysis",
    choice2:
      "Basic usage, analysing standard funnels and basic conversion metrics",
    choice3:
      "Moderate usage, custom funnel analysis, and some optimisation efforts",
    choice4:
      "Advanced usage, in-depth funnel analysis, and continuous conversion optimisation",
    choice5:
      "Granular funnel analysis, advanced testing, and iterative optimisation for maximum conversion",
    category: "category2",
  },
  q11: {
    name: "attributionAndMarketingQ1",
    title:
      "How well do you understand and use Marketing Channels within Adobe Analytics?",
    weight: 0.175,
    choice1: "No understanding or usage of Marketing Channels",
    choice2: "Basic understanding, using default Marketing Channels",
    choice3:
      "Intermediate understanding, customising Marketing Channels with some rules",
    choice4:
      "Advanced understanding, granular Marketing Channel setup with complex rules",
    choice5:
      "Optimised Marketing Channels for accurate attribution and performance analysis",
    category: "category3",
  },
  q12: {
    name: "attributionAndMarketingQ2",
    title: "Are you using attribution models to measure marketing performance?",
    weight: 0.225,
    choice1: "Not using any attribution models",
    choice2: "Using basic models like last-click or first-click attribution",
    choice3: "Using custom attribution models for some marketing channels",
    choice4:
      "Using multi-touch attribution models for a more accurate understanding",
    choice5:
      "Advanced multi-touch attribution models with continuous optimisation for marketing performance",
    category: "category3",
  },
  q13: {
    name: "attributionAndMarketingQ3",
    title:
      "How effectively do you use Adobe Analytics for campaign tracking and analysis?",
    weight: 0.225,
    choice1: "Not using Adobe Analytics for campaign tracking",
    choice2: "Basic tracking of campaign performance with default metrics",
    choice3: "Moderate tracking with custom metrics and some analysis",
    choice4:
      "Advanced tracking, in-depth analysis, and continuous campaign optimisation",
    choice5:
      "Granular analysis, real-time campaign optimisation for maximum ROI",
    category: "category3",
  },
  q14: {
    name: "attributionAndMarketingQ4",
    title:
      "Are you using Adobe Analytics for optimising your paid search and display campaigns?",
    weight: 0.2,
    choice1: "Not using Adobe Analytics for paid campaign optimisation",
    choice2: "Basic optimisation using standard metrics and insights",
    choice3: "Moderate optimisation with custom metrics and some automation",
    choice4: "Advanced optimisation using data-driven insights and automation",
    choice5:
      "Granular insights, automation, and continuous testing for maximum efficiency in paid campaigns",
    category: "category3",
  },
  q15: {
    name: "attributionAndMarketingQ5",
    title:
      "How well do you leverage Adobe Analytics for email marketing performance measurement?",
    weight: 0.175,
    choice1: "Not using Adobe Analytics for email performance measurement",
    choice2: "Basic measurement using standard email metrics",
    choice3: "Moderate measurement with custom email metrics and some analysis",
    choice4:
      "Advanced measurement with in-depth analysis and email optimisation",
    choice5:
      "Granular insights, testing, and continuous email campaign optimisation",
    category: "category3",
  },
  q16: {
    name: "personalisationAndAudienceSegmentationQ1",
    title:
      "How effectively are you using audience segmentation in Adobe Analytics?",
    weight: 0.175,
    choice1: "Not using audience segmentation",
    choice2: "Basic segmentation with a few predefined segments",
    choice3:
      "Intermediate segmentation with custom segments based on user behavior",
    choice4:
      "Advanced segmentation using multiple data points and dynamic segments",
    choice5:
      "Granular insights, cross-platform data, and real-time audience activation",
    category: "category4",
  },
  q17: {
    name: "personalisationAndAudienceSegmentationQ2",
    title:
      "How well are you using Adobe Audience Manager for audience creation and activation?",
    weight: 0.225,
    choice1: "Not using Adobe Audience Manager",
    choice2: "Basic usage, creating simple audiences and limited activation",
    choice3:
      "Intermediate usage, creating custom audiences and activating across channels",
    choice4:
      "Advanced usage, creating complex audiences with rich data sources and advanced activation",
    choice5:
      "Leveraging predictive modeling, real-time activation, and continuous audience optimisation in Adobe Audience Manager",
    category: "category4",
  },
  q18: {
    name: "personalisationAndAudienceSegmentationQ3",
    title:
      "Are you leveraging Adobe Target for website personalisation and testing?",
    weight: 0.2,
    choice1: "Not using Adobe Target for personalisation or testing",
    choice2: "Basic personalisation with simple rules and limited testing",
    choice3: "Moderate personalisation with custom rules and some A/B testing",
    choice4:
      "Advanced personalisation using AI-driven recommendations and extensive testing",
    choice5:
      "Real-time optimisation, advanced testing, and continuous improvement in Adobe Target",
    category: "category4",
  },
  q19: {
    name: "personalisationAndAudienceSegmentationQ4",
    title:
      "How well are you utilising Adobe Analytics data to create personalised customer experiences?",
    weight: 0.175,
    choice1: "Not using Adobe Analytics data for personalisation",
    choice2: "Basic personalisation with limited data points and rules",
    choice3:
      "Moderate personalisation with custom data points and some automation",
    choice4:
      "Advanced personalisation with data-driven insights and extensive automation",
    choice5:
      "Granular insights, real-time adjustments, and continuous optimisation of personalised customer experiences",
    category: "category4",
  },
  q20: {
    name: "personalisationAndAudienceSegmentationQ5",
    title:
      "Are you using Adobe Analytics to analyse and optimise customer journey across touchpoints?",
    weight: 0.225,
    choice1: "Not analysing or optimising customer journey",
    choice2: "Basic analysis of standard customer journey touchpoints",
    choice3: "Moderate analysis with custom touchpoints and some optimisation",
    choice4:
      "Advanced analysis of cross-platform touchpoints and continuous optimisation",
    choice5:
      "Expert analysis with granular insights, predictive modeling, and real-time journey optimisation",
    category: "category4",
  },
  q21: {
    name: "dataGovernanceAndPrivacyQ1",
    title:
      "How well have you implemented data governance policies for Adobe Analytics data?",
    weight: 0.2,
    choice1: "No data governance policies in place",
    choice2: "Basic policies for data storage and access",
    choice3: "Intermediate policies covering data quality, storage, and access",
    choice4:
      "Advanced policies with comprehensive guidelines, audits, and enforcement",
    choice5:
      "Comprehensive policies ensuring data quality, security, and compliance at all levels of the organization",
    category: "category5",
  },
  q22: {
    name: "dataGovernanceAndPrivacyQ2",
    title:
      "Are you using Adobe Analytics features to ensure GDPR and CCPA compliance?",
    weight: 0.25,
    choice1: "Not using Adobe Analytics for compliance",
    choice2: "Basic compliance with minimal data privacy settings",
    choice3:
      "Intermediate compliance with custom data privacy settings and user consent management",
    choice4:
      "Intermediate compliance with custom data privacy settings and user consent management",
    choice5:
      "Granular data privacy controls, real-time monitoring, and a proactive approach to GDPR and CCPA compliance",
    category: "category5",
  },
  q23: {
    name: "dataGovernanceAndPrivacyQ3",
    title:
      "How effectively do you manage user access and permissions within Adobe Analytics?",
    weight: 0.175,
    choice1: "Not managing user access or permissions",
    choice2: "Basic management with minimal access control",
    choice3:
      "Intermediate management with custom access levels and some monitoring",
    choice4:
      "Advanced management with granular access control, regular audits, and enforcement",
    choice5:
      "Automated access control, continuous monitoring, and strict enforcement of user access and permissions policies",
    category: "category5",
  },
  q24: {
    name: "dataGovernanceAndPrivacyQ4",
    title:
      "Are you using Adobe Analytics data for privacy-focused customer insights?",
    weight: 0.15,
    choice1: "Not using Adobe Analytics for privacy-focused insights",
    choice2: "Basic insights with limited consideration for data privacy",
    choice3: "Moderate insights with some privacy controls in place",
    choice4:
      "Advanced insights with privacy-focused data usage and customer consent management",
    choice5:
      "Strong focus on data privacy, customer consent, and ethical data usage for privacy-focused customer insights",
    category: "category5",
  },
  q25: {
    name: "dataGovernanceAndPrivacyQ5",
    title:
      "How well do you monitor data quality and accuracy in Adobe Analytics?",
    weight: 0.225,
    choice1: "Not monitoring data quality or accuracy",
    choice2: "Basic monitoring with occasional data quality checks",
    choice3:
      "Intermediate monitoring with regular data quality checks and some validation",
    choice4:
      "Advanced monitoring with continuous data quality checks, validation, and error resolution",
    choice5:
      "Automated data quality checks, real-time validation, and proactive error resolution for monitoring data quality and accuracy",
    category: "category5",
  },
};

const createSurveyQuestions = () => {
  const pages = [];
  pages.push({
    name: "PersonalDetails",
    elements: [
      {
        type: "text",
        name: "FirstName",
        isRequired: true,
        title: "Enter your first name",
        hideNumber: true,
      },
      {
        type: "text",
        name: "LastName",
        isRequired: true,
        title: "Enter your last name",
        hideNumber: true,
      },
      {
        type: "text",
        name: "CompanyName",
        isRequired: true,
        title: "What company do you work for?",
        hideNumber: true,
      },
      {
        type: "text",
        name: "JobTitle",
        title: "What is your job title?",
        hideNumber: true,
      },
      {
        type: "text",
        name: "Email",
        isRequired: true,
        validators: [{ type: "email" }],
        title: "Enter your email address:",
        hideNumber: true,
      },
      {
        type: "checkbox",
        name: "contactby",
        title:
          "By ticking this box you agree to collection, use, storage and protection of your personal information as outlined in our privacy policy.",
        isRequired: true,
        choices: [{ text: "I agree" }],
        hideNumber: true,
      },
      {
        type: "html",
        name: "Mylink",
        title: "Our privacy policy",
        html: "<a href='https://station10.co.uk/privacy-policy/'  target='_blank'>Our privacy policy</a>",
        hideNumber: true,
      },
    ],
  });
  // const surveyArray = [];
  for (let i = 1; i <= 25; i++) {
    //for (let i = 1; i <= 1; i++) {
    // Create a new object for each iteration
    const surveyQuestion = {
      elements: [
        {
          type: "radiogroup",
          name: surveyQuestions["q" + i].name,
          isRequired: true,
          category: surveyQuestions["q" + i].category,
          title: surveyQuestions["q" + i].title,
          choices: [
            {
              value: surveyQuestions["q" + i].choice1,
              score: 0,
              weight: surveyQuestions["q" + i].weight,
            },
            {
              value: surveyQuestions["q" + i].choice2,
              score: 1,
              weight: surveyQuestions["q" + i].weight,
            },
            {
              value: surveyQuestions["q" + i].choice3,
              score: 2,
              weight: surveyQuestions["q" + i].weight,
            },
            {
              value: surveyQuestions["q" + i].choice4,
              score: 3,
              weight: surveyQuestions["q" + i].weight,
            },
            {
              value: surveyQuestions["q" + i].choice5,
              score: 4,
              weight: surveyQuestions["q" + i].weight,
            },
          ],
        },
      ],
    };
    pages.push(surveyQuestion);
  }
  return { pages };
};

export const surveyJson = createSurveyQuestions();
